import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Note, Inf, Org, Det } from "../Maps";
import { Link } from "react-router-dom";

const Home = ({ theme, setTheme }) => {
  const [showMore, setShowMore] = useState(false);
  const [toShow, setToShow] = useState("");

  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <section className={`${theme === "dark" ? "dk" : "bg-[#ebe8e1]"}`}>
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />

      <section
        className={` ${
          theme === "light" && "bg-[#ebe8e1]"
        } px-[30px] lg:px-[50px]`}
      >
        <Header theme={theme} />
      </section>

      <section className="flex flex-col items-center justify-center py-[50px] px-[20px] lg:px-[100px]">
        <h2 className="dark:text-white opacity-50">Welcome to Aerodrome</h2>

        <h2
          className={`text-[1.5rem] lg:text-[3rem] flex flex-col items-center text-center ${
            theme === "dark" ? "text-[white]" : "text-[black]"
          } pb-[40px]`}
        >
          The central trading & <br />
          liquidity marketplace
          <div className="flex flex-row items-center">
            on
            <img
              src="./images/blogo.svg"
              className="w-[90px] lg:w-[165px] ml-[20px]"
            />
          </div>
        </h2>

        <img
          src={theme === "dark" ? "./images/plane1.svg" : "./images/plane2.svg"}
        />

        <h2
          className={`lg:w-[40%] text-center opacity-70 mt-[-40px] leading-[1.5rem] dark:text-white`}
        >
          Aerodrome Finance is a next-generation AMM designed to serve as Base's
          central liquidity hub, combining a powerful liquidity incentive
          engine, vote-lock governance model, and friendly user experience.
          Aerodrome inherits the latest features from Velodrome V2. Aerodrome
          NFTs vote to distribute token emissions and receive incentives and
          fees generated by the protocol.
        </h2>

        <div className="flex flex-col lg:flex-row items-center justify-between flex-wrap pt-[100px] w-[100%]">
          {Note.map((us, id) => (
            <div
              key={id}
              className="border-solid border-[1px] border-gray-800 flex flex-row items-start w-[100%] lg:w-[33%] p-6 justify-between my-[5px] rounded-lg
            border-opacity-10 dark:border-white dark:border-opacity-10 dark:text-white text-[black] min-h-[98px]"
            >
              {us.text}

              <img
                src={
                  theme === "dark"
                    ? "./images/lightCheck.svg"
                    : "./images/darkCheck.svg"
                }
              />
            </div>
          ))}
        </div>

        <section className="flex flex-col lg:flex-row items-center lg:items-start justify-between pt-[50px] lg:pt-[220px] w-[100%]">
          <div className="flex flex-col items-start lg:mt-[-90px] pb-[80px] lg:pb-[0px]">
            <h2 className="uppercase dark:text-white text-[black] text-xs">
              How it works
            </h2>
            <h2 className="dark:text-white text-[black] text-2xl lg:w-[50%] lg:text-3xl in font-[900] pt-[10px]">
              Designed to reward participants that enable the sustainable growth
              of the protocol.
            </h2>
          </div>

          <img
            src={`${
              theme === "dark"
                ? "./images/darkHow.svg"
                : "./images/lightHow.svg"
            }`}
          />
        </section>

        <section className="flex flex-col lg:flex-row items-center justify-between">
          {Inf.map((us, id) => (
            <div
              key={id}
              className="w-[100%] mt-[20px] lg:mt-[0px] lg:w-[23%] flex flex-col bg-white dark:bg-opacity-5 cursor-pointer rounded-lg shadow hover:bg-opacity-[.3]
             h-[450px] justify-between
            "
            >
              <div className="flex flex-col items-start p-[20px]">
                <h2 className="text-[black] dark:text-white font-[700] in">
                  {us.header}
                </h2>
                <h2 className="text-[black] dark:text-white in pt-[15px]">
                  {us.body}
                </h2>
              </div>

              <img src={us.image} />
            </div>
          ))}
        </section>

        <section className="flex flex-col lg:flex-row items-center justify-between w-[100%] pt-[200px]">
          {Det.map((us, id) => (
            <div
              key={id}
              className="flex flex-col items-center justify-center pt-[80px] lg:pt-[0px]"
            >
              <h2 className="text-3xl text-[black] dark:text-white lg:text-4xl font-semibold in">
                {" "}
                {us.value}
              </h2>
              <h2 className="text-xs uppercase opacity-70 dark:text-white text-[black] font-semibold in pt-[10px]">
                {us.name}
              </h2>
            </div>
          ))}
        </section>
      </section>

      <section
        className={` ${
          theme === "dark" ? "bg-[#050a1d] text-white" : "bg-[#fdfdfd]"
        } pt-[50px]`}
      >
        <section className="flex flex-row lg:flex-nowrap flex-wrap items-end justify-center w-[100%] pb-[30px]">
          {Org.map((us, id) => (
            <a
              key={id}
              className="px-[10px] flex flex-col items-center w-[110px] max-h-[80px]"
              onMouseEnter={() => {
                setToShow(us.name);
                setShowMore(true);
              }}
              onMouseLeave={() => {
                setToShow("");
                setShowMore(false);
              }}
            >
              <div className="pb-[20px]">
                <h2
                  className={`
              ${toShow === us.name ? "flex" : "hidden"} text-xs
            `}
                >
                  {us.name}
                </h2>
              </div>
              <img src={us.image} className="" />
            </a>
          ))}
        </section>

        <div className=" mt-[40px] flex flex-row items-center justify-center w-[100%] border-solid border-y-[1px] border-[#959cbf] py-[10px] border-opacity-20">
          <h2 className="text-[12px] font-[400] text-[#959cbf]">
            Data and metrics are openly available on{" "}
          </h2>
          <a
            className="text-[12px] font-[400] text-[#959cbf] pl-[5px] underline hover:no-underline cursor-pointer"
            href="https://tokenterminal.com/terminal/projects/aerodrome"
          >
            {" "}
            Token Terminal
          </a>
        </div>

        <div className="flex flex-col items-center justify-center py-[100px]">
          <img src="./images/base.svg" className="w-[144px]" />

          <h2 className="dark:text-white text-[black] text-center py-[40px] text-xl md:text-3xl">
            Looking to get <br />
            started on Aerodrome?
          </h2>

          <Link
            to="/join"
            className="capitalize bg-transparent border-solid border-[1px] rounded-lg border-gray-900 px-[15px] py-[10px] hover:border-[#ffffff33]"
          >
            onboarding guide
          </Link>
        </div>

        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Home;
