import { useState } from "react";
import { Link } from "react-router-dom";

const DClong = ({ theme }) => {
  const [show, setShow] = useState(false);
  return (
    <section class="w-[100%] text-black dark:text-white" id="intro">
      <div class="lg:flex gap-20 px-4 py-6 lg:pt-12 items-start" >
        <div class="sticky top-0 sm:top-10 z-40 lg:w-3/12 pb-12">
          <div class="hidden lg:block">
            <div class="space-y-1">
              <div class="opacity-20 text-sm pb-2">Docs:</div>
                          <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("intro");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Intro
                </div>
              </button>
                          <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("tokenomics");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Tokenomics
                </div>
              </button>
                          <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("emissions");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Emissions
                </div>
              </button>
              <div class="opacity-30 text-xs pb-2 pt-8">Security:</div>
              <Link to="/security" class="block">
                <div class="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Contracts
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-plus"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div className="block lg:hidden bg-white dark:bg-gray-900 p-4 rounded-lg flex items-center justify-between">
          <div className="pl-2 opacity-30 dark:text-white">Docs</div>
          <div className="w-fit" data-testid="flowbite-tooltip-target">
            <button
              type="button"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
            onClick={() => {
                setShow(!show)
            }}
            >
              <span className="flex items-center rounded-md text-xs px-2 py-1">
                Quick Navigation
                <i class='bx bx-chevron-down' ></i>
              </span>
            </button>
          </div>
          {
            show && 
          <div
            data-testid="flowbite-tooltip"
            
            className="z-30 w-fit rounded-lg divide-y divide-gray-100 shadow-sm transition-opacity duration-100 
             bg-white text-gray-900 dark:bg-gray-900 border border-neutral-200 dark:border-gray-700
             dark:border-opacity-70 dark:text-white rounded"
            style={{
                position: "absolute",
                top: "50px",
                left: "8px"
            }}
            id=":re:"
            role="tooltip"
          >
            <div className="text-sm text-gray-700 dark:text-gray-200">
              <ul className="py-1">
                <li className="flex items-center justify-start mx-2 mt-2 mb-1 py-2 px-2 text-sm text-gray-700 cursor-pointer hover:bg-neutral-100 hover:bg-opacity-50 dark:text-gray-200 dark:hover:bg-gray-850 dark:hover:bg-opacity-50 dark:hover:text-white">
                  <div className="w-64 sm:w-96">
                    <div className="space-y-1">
                      <div className="opacity-30 text-sm pb-2">Docs:</div>
                                                <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("intro");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Intro
                </div>
              </button>
                                        <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("tokenomics");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Tokenomics
                </div>
              </button>
                                        <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("emissions");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
                <div class="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Emissions
                </div>
              </button>
                      <div className="opacity-20 text-sm pb-2 pt-8">Security</div>
                      <Link to="/security" class="block">
                <div class="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                  Contracts
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-plus"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                  </svg>
                </div>
              </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          }
        </div>
        </div>
        <div class="w-full lg:w-7/12"  onClick={() => {
          setShow(false)
        }}>
          <h1 class="mb-6 text-2xl text-gray-600 dark:text-gray-400">
            About Aerodrome
          </h1>
          <p class="mb-4">
            Aerodrome Finance is a next-generation AMM designed to serve as
            Base's central liquidity hub, combining a powerful liquidity
            incentive engine, vote-lock governance model, and friendly user
            experience. Aerodrome inherits the latest features from Velodrome
            V2.
          </p>
          <p class="mb-4">
            Aerodrome launched on <strong>28th of August, 2023</strong> on BASE
            network.
          </p>
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Aerodrome Mechanics
          </h2>
          <img
            src={`${
              theme === "dark"
                ? "./images/darkHow.svg"
                : "./images/lightHow.svg"
            }`}
          />

          <ol class="mb-8 list-inside list-decimal">
            <li>
              The protocol is designed to enable token swaps and generate fees
              (from
              <strong>Traders</strong>) by attracting liquidity.
            </li>
            <li>
              Every epoch liquidity providers (<strong>LPs</strong>) receive{" "}
              <code>$AERO</code> token emissions proportionally to the votes the
              pools accumulate. Only staked (in the protocol gauges) liquidity
              receive emissions.
            </li>
            <li>
              Participants can lock their <code>$AERO</code> to be able to vote
              on the next epoch distribution of emissions, becoming{" "}
              <strong>veAERO Voters</strong>.
            </li>
            <li>
              <strong>veAERO Voters</strong> are rewarded (proportionally to
              locked amounts) for their votes with 100% of the protocol trading
              fees from the previous epoch and any additional voters incentives
              from the current epoch.
            </li>
          </ol>
          <hr
            class="border-gray-700/10 dark:border-gray-700/40 my-20"
            id="tokenomics"
          />
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Tokenomics
          </h2>
          <p class="mb-4">
            Aerodrome Finance uses two tokens to manage its utility and
            governance:
          </p>
          <ul class="mb-8 list-inside list-disc">
            <li>
              <code>$AERO</code> — ERC-20 utility token of the protocol
            </li>
            <li>
              <code>$veAERO</code> — ERC-721 governance token in the form of an
              NFT (non-fungible token)
            </li>
          </ul>
          <p class="mb-4">
            <code>$AERO</code> is distributed to liquidity providers through
            emissions.
          </p>
          <p class="mb-4">
            <code>$veAERO</code> is used for governance. Any <code>$AERO</code>{" "}
            holder can vote-escrow their tokens and receive a{" "}
            <code>$veAERO</code> (also known as Lock or veNFT) in exchange.
            Additional tokens can be added to the <code>$veAERO</code> NFT at
            any time.
          </p>
          <p class="mb-4">
            The lock period (also known as vote-escrowed period, hence the{" "}
            <em>ve</em> prefix) can be up to 4 years, following the linear
            relationship shown below:
          </p>
          <ul class="mb-8 list-inside list-disc">
            <li>
              100 <code>$AERO</code> locked for 4 years will become 100{" "}
              <code>$veAERO</code>
            </li>
            <li>
              100 <code>$AERO</code> locked for 1 year will become 25{" "}
              <code>$veAERO</code>
            </li>
          </ul>
          <p class="mb-4">
            The longer the vesting time, the higher the voting power (voting
            weight) of the underlying locked balance.
          </p>
          <p class="mb-4">
            Additionally, Aerodrome Locks (veNFTs) can be set into Auto-Max
            Lock, which are treated by the protocol as being locked for the
            maximum duration of 4 years, and their voting power does not decay.
            The Auto-Max Lock feature can be turned on and off for each Lock
            (veNFT).
          </p>
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Initial Distribution
          </h2>
          <br />
          <div
            data-testid="table-element"
            class="relative overflow-x-auto shadow-md sm:rounded-lg"
          >
            <table class="w-full text-left text-sm text-gray-500 dark:text-gray-400">
              <thead class="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400 bg-gray-300 dark:bg-gray-700">
                <tr>
                  <th class="px-6 py-3 w-3/4">AERO</th>
                  <th class="px-6 py-3"></th>
                  <th class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-900">
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">Voters Incentives</td>
                  <td class="px-6 py-4">40M</td>
                  <td class="px-6 py-4">8%</td>
                </tr>
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">Genesis Liquidity Pool</td>
                  <td class="px-6 py-4">10M</td>
                  <td class="px-6 py-4">2%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div
            data-testid="table-element"
            class="relative overflow-x-auto shadow-md sm:rounded-lg"
          >
            <table class="w-full text-left text-sm text-gray-500 dark:text-gray-400">
              <thead class="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400 bg-gray-300 dark:bg-gray-700">
                <tr>
                  <th class="px-6 py-3 w-3/4">veAERO</th>
                  <th class="px-6 py-3"></th>
                  <th class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-900">
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">Airdrop for veVELO Lockers</td>
                  <td class="px-6 py-4">200M</td>
                  <td class="px-6 py-4">40%</td>
                </tr>
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">
                    Ecosystem Pools and Public Goods Funding
                  </td>
                  <td class="px-6 py-4">105M</td>
                  <td class="px-6 py-4">21%</td>
                </tr>
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">Aerodrome Team (Auto Max-Locked)</td>
                  <td class="px-6 py-4">70M</td>
                  <td class="px-6 py-4">14%</td>
                </tr>
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">Protocol Grants</td>
                  <td class="px-6 py-4">50M</td>
                  <td class="px-6 py-4">10%</td>
                </tr>
                <tr data-testid="table-row-element" class="">
                  <td class="px-6 py-4">AERO Pools Votepower</td>
                  <td class="px-6 py-4">25M</td>
                  <td class="px-6 py-4">5%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr
            class="border-gray-700/10 dark:border-gray-700/40 my-20"
            id="emissions"
          />
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Emissions
          </h2>
          <p class="mb-4">
            The initial supply of <code>$AERO</code> is 500M, with 450M
            distributed as vote-locked (<code>$veAERO</code>) tokens.
          </p>
          <p class="mb-4">
            Weekly emissions will being at 10M <code>$AERO</code> (2% of the
            initial supply). The emission schedule will follow three phases:
          </p>
          <p class="mb-4">
            <strong>Take-off</strong>: During the first 14 weeks (epochs),
            emissions will increase at 3% per week, allowing for rapid growth in
            protocol activity and partner onboarding.
          </p>
          <p class="mb-4">
            <strong>Cruise</strong>: After Epoch 14, emissions will decay at 1%
            per epoch, ensuring total supply inflation decreases over time as
            the protocol matures.
          </p>
          <p class="mb-4">
            <strong>Aero Fed</strong>: When emissions programmatically drop
            under 9M per epoch, approximately Epoch 67, veAERO voters will take
            control of Aerodrome's monetary policy through the Aero Fed system.
            Voters will collectively make one of three choices each epoch:
          </p>
          <ul class="mb-8 list-inside list-disc">
            <li>
              Increase emissions by 0.01% of total supply (0.52% annualized)
            </li>
            <li>
              Decrease emissions by 0.01% of total supply (0.52% annualized)
            </li>
            <li>Maintain emissions unchanged as a % of total supply</li>
          </ul>
          <p class="mb-4">
            The winning vote will be determined by simple majority vote. If an
            Increase or Decrease is selected, the emission rate will change one
            full epoch after the vote. A max emission rate will be set at 1% of
            total supply per week (52% annualized) and a minimum rate at 0.01%
            per week (0.52% annualized).
          </p>
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Rebase
          </h2>
          <p class="mb-4">
            <code>$veAERO</code> holders receive a rebase proportional to{" "}
            <code>$AERO</code> emissions and to the ratio of{" "}
            <code>$veAERO</code> to <code>$AERO</code> supply, reducing vote
            power dilution for
            <code>$veAERO</code>!
          </p>
          <br />
          <p class="mb-4">
            The weekly rebase amount is calculated with the following formula:
          </p>
          <blockquote class="my-4 border-l italic opacity-70 pl-4 text-gray-600 dark:text-gray-400">
            <p class="mb-4">
              rebase = weeklyEmissions × (1 - veAERO.totalSupply ÷
              AERO.totalsupply)ˆ2 × 0.5
            </p>
          </blockquote>
          <p class="mb-4">
            This rebase formula will reward <code>$veAERO</code> holders most
            when locking rates decrease, incentivizing new lockers to step in.{" "}
            <code>$veAERO</code> supply does not affect weekly emissions
            distributed to liquidity providers.
          </p>
          <br />
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Projected Emission Schedule
          </h2>
          <p class="mb-4">
            <strong>Note</strong>:{" "}
            <em>
              projection below uses a 40% emissions lock rate and assumes Aero
              Fed keeps emissions constant
            </em>
            .
          </p>
          <br />
          {/* <div style="height: 600px; width: 100%;">
            <div id="reactgooglegraph-2" style="height: 600px; width: 100%;">
              <div style="position: relative;">
                <div
                  style="position: relative; width: 704px; height: 600px;"
                  dir="ltr"
                >
                  <div
                    style="position: absolute; left: 0px; top: 0px; width: 100%; height: 100%;"
                    aria-label="A chart."
                  >
                    <svg
                      width="704"
                      height="600"
                      style="overflow: hidden;"
                      aria-label="A chart."
                    >
                      <defs id="_ABSTRACT_RENDERER_ID_4">
                        <clipPath id="_ABSTRACT_RENDERER_ID_5">
                          <rect x="135" y="115" width="435" height="371"></rect>
                        </clipPath>
                      </defs>
                      <rect
                        x="0"
                        y="0"
                        width="704"
                        height="600"
                        stroke="none"
                        stroke-width="0"
                        fill="#ebe9e4"
                      ></rect>
                      <g>
                        <text
                          text-anchor="start"
                          x="135"
                          y="66.9"
                          font-family="Arial"
                          font-size="14"
                          font-weight="bold"
                          stroke="none"
                          stroke-width="0"
                          fill="#666666"
                        >
                          AERO Emissions, M
                        </text>
                        <rect
                          x="135"
                          y="55"
                          width="435"
                          height="14"
                          stroke="none"
                          stroke-width="0"
                          fill-opacity="0"
                          fill="#ffffff"
                        ></rect>
                      </g>
                      <g>
                        <rect
                          x="135"
                          y="78"
                          width="413"
                          height="14"
                          stroke="none"
                          stroke-width="0"
                          fill-opacity="0"
                          fill="#ffffff"
                        ></rect>
                        <g>
                          <rect
                            x="135"
                            y="78"
                            width="118"
                            height="14"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></rect>
                          <g>
                            <text
                              text-anchor="start"
                              x="168"
                              y="89.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#666666"
                            >
                              LP Emissions
                            </text>
                          </g>
                          <path
                            d="M135,85L163,85"
                            stroke="#79f8db"
                            stroke-width="3"
                            fill-opacity="1"
                            fill="none"
                          ></path>
                        </g>
                        <g>
                          <rect
                            x="276"
                            y="78"
                            width="140"
                            height="14"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></rect>
                          <g>
                            <text
                              text-anchor="start"
                              x="309"
                              y="89.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#666666"
                            >
                              veAERO Rebase
                            </text>
                          </g>
                          <path
                            d="M276,85L304,85"
                            stroke="#2180df"
                            stroke-width="3"
                            fill-opacity="1"
                            fill="none"
                          ></path>
                        </g>
                        <g>
                          <rect
                            x="439"
                            y="78"
                            width="109"
                            height="14"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></rect>
                          <g>
                            <text
                              text-anchor="start"
                              x="472"
                              y="89.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#666666"
                            >
                              Total Supply
                            </text>
                          </g>
                          <path
                            d="M439,85L467,85"
                            stroke="#ea1000"
                            stroke-width="3"
                            fill-opacity="1"
                            fill="none"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <rect
                          x="135"
                          y="115"
                          width="435"
                          height="371"
                          stroke="none"
                          stroke-width="0"
                          fill-opacity="0"
                          fill="#ffffff"
                        ></rect>
                        <g clip-path="url(https://aerodrome.finance/docs#_ABSTRACT_RENDERER_ID_5)">
                          <g>
                            <rect
                              x="135"
                              y="485"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="439"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="393"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="346"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="300"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="254"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="208"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="161"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="115"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="462"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="416"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="369"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="323"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="277"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="231"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="184"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="138"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="485"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="444"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="403"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="362"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="321"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="279"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="238"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="197"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="156"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="115"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#cccccc"
                            ></rect>
                            <rect
                              x="135"
                              y="464"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="423"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="382"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="341"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="300"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="259"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="218"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="177"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                            <rect
                              x="135"
                              y="136"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#dfddda"
                            ></rect>
                          </g>
                          <g>
                            <rect
                              x="135"
                              y="485"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#333333"
                            ></rect>
                            <rect
                              x="135"
                              y="485"
                              width="435"
                              height="1"
                              stroke="none"
                              stroke-width="0"
                              fill="#333333"
                            ></rect>
                          </g>
                          <g>
                            <path
                              d="M178.9,254.25C178.9,254.25,236.76666666666665,150.1875,265.7,138.625C294.6333333333333,127.0625,323.5666666666667,161.75,352.5,184.875C381.43333333333334,208,410.3666666666666,261.95833333332564,439.3,277.375C468.23333333333335,292.79166666667436,526.0999999999999,277.375,526.0999999999999,277.375"
                              stroke="#79f8db"
                              stroke-width="3"
                              fill-opacity="1"
                              fill="none"
                            ></path>
                            <path
                              d="M178.9,484.34375C178.9,484.34375,236.76666666666665,478.5625,265.7,476.25C294.6333333333333,473.9375,323.5666666666667,471.625,352.5,470.46875C381.43333333333334,469.3125,410.3666666666666,469.890625,439.3,469.3125C468.23333333333335,468.734375,526.0999999999999,467,526.0999999999999,467"
                              stroke="#2180df"
                              stroke-width="3"
                              fill-opacity="1"
                              fill="none"
                            ></path>
                            <path
                              d="M178.9,382.72222222222223C178.9,382.72222222222223,236.76666666666665,360.28240740740057,265.7,346.75C294.6333333333333,333.21759259259943,323.5666666666667,323.7962962962894,352.5,301.52777777777777C381.43333333333334,279.2592592593278,410.3666666666666,238.83333333333334,439.3,213.1388888888889C468.23333333333335,187.44444444444446,526.0999999999999,147.36111111111114,526.0999999999999,147.36111111111114"
                              stroke="#ea1000"
                              stroke-width="3"
                              fill-opacity="1"
                              fill="none"
                            ></path>
                          </g>
                        </g>
                        <g></g>
                        <g>
                          <g>
                            <text
                              text-anchor="middle"
                              x="178.9"
                              y="506.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#222222"
                            >
                              1
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="middle"
                              x="265.7"
                              y="506.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#222222"
                            >
                              15
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="middle"
                              x="352.5"
                              y="506.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#222222"
                            >
                              30
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="middle"
                              x="439.3"
                              y="506.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#222222"
                            >
                              67
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="middle"
                              x="526.0999999999999"
                              y="506.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#222222"
                            >
                              100
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="490.4"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              0
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="444.15"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              2
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="397.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              4
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="351.65"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              6
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="305.4"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              8
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="259.15"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              10
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="212.9"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              12
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="166.65"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              14
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="end"
                              x="121"
                              y="120.4"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              16
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="490.4"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              0
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="449.28889999999996"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              200
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="408.1778"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              400
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="367.06669999999997"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              600
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="325.9556"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              800
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="284.84439999999995"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              1,000
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="243.7333"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              1,200
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="202.6222"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              1,400
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="161.5111"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              1,600
                            </text>
                          </g>
                          <g>
                            <text
                              text-anchor="start"
                              x="584"
                              y="120.4"
                              font-family="Arial"
                              font-size="14"
                              stroke="none"
                              stroke-width="0"
                              fill="#444444"
                            >
                              1,800
                            </text>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <text
                            text-anchor="middle"
                            x="352.5"
                            y="559.4"
                            font-family="Arial"
                            font-size="14"
                            font-style="italic"
                            stroke="none"
                            stroke-width="0"
                            fill="#000000"
                          >
                            Epoch
                          </text>
                          <rect
                            x="135"
                            y="547.5"
                            width="435"
                            height="14"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></rect>
                        </g>
                        <g>
                          <text
                            text-anchor="middle"
                            x="57.4"
                            y="300.5"
                            font-family="Arial"
                            font-size="14"
                            font-style="italic"
                            transform="rotate(-90 57.4 300.5)"
                            stroke="none"
                            stroke-width="0"
                            fill="#666666"
                          >
                            Distribution
                          </text>
                          <path
                            d="M45.49999999999999,486L45.500000000000014,115L59.500000000000014,115L59.49999999999999,486Z"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></path>
                        </g>
                        <g>
                          <text
                            text-anchor="middle"
                            x="666.4"
                            y="300.5"
                            font-family="Arial"
                            font-size="14"
                            font-style="italic"
                            transform="rotate(-90 666.4 300.5)"
                            stroke="none"
                            stroke-width="0"
                            fill="#666666"
                          >
                            Total Supply
                          </text>
                          <path
                            d="M654.5,486L654.5,114.99999999999999L668.5,114.99999999999999L668.5,486Z"
                            stroke="none"
                            stroke-width="0"
                            fill-opacity="0"
                            fill="#ffffff"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                    </svg>
                    <div
                      aria-label="A tabular representation of the data in the chart."
                      style="position: absolute; left: -10000px; top: auto; width: 1px; height: 1px; overflow: hidden;"
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Epoch</th>
                            <th>LP Emissions</th>
                            <th>veAERO Rebase</th>
                            <th>Total Supply</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>10</td>
                            <td>0.05</td>
                            <td>500</td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>15</td>
                            <td>0.4</td>
                            <td>675</td>
                          </tr>
                          <tr>
                            <td>30</td>
                            <td>13</td>
                            <td>0.65</td>
                            <td>895</td>
                          </tr>
                          <tr>
                            <td>67</td>
                            <td>9</td>
                            <td>0.7</td>
                            <td>1,325</td>
                          </tr>
                          <tr>
                            <td>100</td>
                            <td>9</td>
                            <td>0.8</td>
                            <td>1,645</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  style="display: none; position: absolute; top: 610px; left: 701px; white-space: nowrap; font-family: Arial; font-size: 14px;"
                  aria-hidden="true"
                >
                  Total Supply
                </div>
                <div></div>
              </div>
            </div>
          </div> */}
          <br />
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Emission Rewards
          </h2>
          <p class="mb-4">
            Each epoch, <code>$AERO</code> emissions are distributed to
            liquidity pools proportionally to the <code>$veAERO</code> voting
            power the pools receive.
          </p>
          <p class="mb-4">
            Liquidity providers (LPs) can stake their LP positions to receive a
            share of the <code>$AERO</code> distributed to each pool
            proportionally to the size of positions and time staked.
          </p>
          <p class="mb-4">
            These rewards are distributed during the whole epoch and available
            for claiming as these accrue.
          </p>
          <h2 class="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
            Team Emissions
          </h2>
          <p class="mb-4">
            While a fully autonomous and immutable protocol is an admirable
            objective and it comes at a cost. Aerodrome will ensure its
            long-term sustainability by employing a dedicated team focused on
            supporting the product decentralization, documentation, community,
            and ecosystem.
          </p>
          <p class="mb-4">
            To cover ongoing expenses and all the upcoming development efforts,
            5% of the emissions will be going to the team address.
          </p>
          <div class="text-[#0052ff] mt-48 mb-16 text-center">***</div>
          <p class="text-center mb-4 text-lg sm:text-3xl font-bold sm:leading-10">
            <em>
              "Now it's time
              <br />
              to leave the capsule
              <br />
              if you dare"
            </em>
          </p>
          <p class="text-center mb-48 text-lg opacity-50">David Bowie</p>
        </div>
      </div>
    </section>
  );
};

export default DClong;
