import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Joinlong } from "../Longs";

const Join = ({ theme, setTheme }) => {
  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <section
      className={`${theme === "dark" ? "hrr" : "jn"} min-h-[100vh]`}
    >
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />
      <section
        className={` px-[30px] lg:px-[50px]`}
      >
        <Header theme={theme} />
      </section>

      <section
        className={`flex flex-col items-center justify-center px-[20px] lg:px-[100px] min-h-[70vh]`}
      >
        <section>
        <Joinlong theme={theme} />
        </section>
      </section>

      <section
        className={`bg-transparent pt-[50px] `}
      >
        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Join;
