import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header = ({ theme }) => {
  const location = useLocation();

  let log = (
    <span class="hidden lg:inline">
      <svg
        src="/wordmark.svg"
        class="injected-svg h-4 w-auto dark:text-white"
        alt="Aerodrome Finance"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 100 9"
        height="9"
        width="100"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M8.92686 8.75425L8.18707 7.06505H3.73597L2.99617 8.75425H0L4.01955 0.123299H7.90348L11.9107 8.75425H8.92686ZM4.59906 5.11692H7.32397L5.96768 2.02211L4.59906 5.11692Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M21.4208 2.23172H15.0093V3.51403H21.4208V5.36352H15.0093V6.64583H21.4208V8.75425H12.2967V0.123299H21.4208V2.23172Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M32.0876 3.35374C32.0876 4.68537 31.4464 5.73341 30.3984 6.23894L31.9519 8.75425H28.7462L27.4638 6.59651H24.7143V8.75425H22.0017V0.123299H28.7831C30.7436 0.123299 32.0876 1.43027 32.0876 3.35374ZM24.7143 2.41667V4.30314H28.1666C28.6722 4.30314 29.375 4.30314 29.375 3.35374C29.375 2.41667 28.6722 2.41667 28.1666 2.41667H24.7143Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M37.9357 8.87754C34.1381 8.87754 32.5105 7.36096 32.5105 4.42644C32.5105 1.50425 34.1381 0 37.9357 0C41.721 0 43.3609 1.51658 43.3609 4.42644C43.3609 7.34863 41.721 8.87754 37.9357 8.87754ZM37.9357 6.58418C40.0441 6.58418 40.6483 5.75807 40.6483 4.42644C40.6483 3.10714 40.0564 2.29337 37.9357 2.29337C35.8273 2.29337 35.2231 3.09481 35.2231 4.42644C35.2231 5.7704 35.8396 6.58418 37.9357 6.58418Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M50.2902 0.123299C52.4356 0.123299 54.396 1.50425 54.396 4.43877C54.396 7.37329 52.4356 8.75425 50.2902 8.75425H43.9403V0.123299H50.2902ZM49.3654 6.46088C50.5491 6.46088 51.6835 6.28826 51.6835 4.43877C51.6835 2.58928 50.5491 2.41667 49.3654 2.41667H46.6529V6.46088H49.3654Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M65.0677 3.35374C65.0677 4.68537 64.4265 5.73341 63.3785 6.23894L64.9321 8.75425H61.7263L60.444 6.59651H57.6944V8.75425H54.9818V0.123299H61.7633C63.7237 0.123299 65.0677 1.43027 65.0677 3.35374ZM57.6944 2.41667V4.30314H61.1468C61.6523 4.30314 62.3551 4.30314 62.3551 3.35374C62.3551 2.41667 61.6523 2.41667 61.1468 2.41667H57.6944Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M70.9158 8.87754C67.1182 8.87754 65.4907 7.36096 65.4907 4.42644C65.4907 1.50425 67.1182 0 70.9158 0C74.7011 0 76.341 1.51658 76.341 4.42644C76.341 7.34863 74.7011 8.87754 70.9158 8.87754ZM70.9158 6.58418C73.0242 6.58418 73.6284 5.75807 73.6284 4.42644C73.6284 3.10714 73.0366 2.29337 70.9158 2.29337C68.8074 2.29337 68.2033 3.09481 68.2033 4.42644C68.2033 5.7704 68.8197 6.58418 70.9158 6.58418Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M90.1381 0.123299V8.75425H87.4255V3.72364L84.454 8.75425H82.6045L79.633 3.71131V8.75425H76.9204V0.123299H80.3974L83.5416 5.63477L86.6734 0.123299H90.1381Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M100 2.23172H93.5884V3.51403H100V5.36352H93.5884V6.64583H100V8.75425H90.8759V0.123299H100V2.23172Z"
          fill="currentColor"
        ></path>
      </svg>
    </span>
  );

  const [menu, setMenu] = useState(false);
  useEffect(() => {}, [menu]);

  return (
    <header
      className=" py-[20px] border-solid 
        border-[#11162b0d] dark:border-[#ffffff0d] border-b-[1px] flex flex-col items-center justify-between lg:justify-center w-[100%]"
    >
      <section className="flex flex-row items-center justify-between w-[100%]">
        <Link
          to="/"
          className="flex flex-row items-center w-[30%] justify-start"
        >
          <img src="./images/log.svg" className="w-[30px] mr-[10px]" />
          {log}
        </Link>

        <div className="hidden lg:flex flex-row items-center justify-center w-[40%]">
          <Link
            to="/swap"
            className={`in 
        
        ${
          location.pathname === "/swap"
            ? "underline decoration-sky-500 underline-offset-8 dark:text-opacity-90"
            : "text-opacity-50"
        }
        dark:text-white dark:text-opacity-50 hover:dark:text-opacity-100
         `}
          >
            Swap
          </Link>
          <Link
            to="/liquidity"
            className={`in 
        
        ${
          location.pathname === "/liquidity"
            ? "underline decoration-sky-500 underline-offset-8 dark:text-opacity-90"
            : "text-opacity-50"
        }
        dark:text-white dark:text-opacity-50 ml-[50px] hover:dark:text-opacity-100
         `}
          >
            Liquidity
          </Link>
        </div>

        <div className="w-[30%] flex items-center justify-end ">
          <Link
            to="/connect"
            className={`bg-[#0052ff] h-[40px] flex flex-col items-center justify-center
        text-[14px] py-[10px] px-[15px] text-white rounded-lg hover:opacity-90 in`}
          >
            Connect
          </Link>
          <button
            className="rounded-lg text-[20px] lg:hidden flex bg-opacity-5 dark:hover:bg-opacity-10 bg-white text-gray-500 py-[10px] h-[40px] px-[15px] ml-[10px]
        flex flex-col items-center justify-center cursor-pointer"
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <i class="bx bx-menu"></i>
          </button>
        </div>
      </section>

      {menu && (
        <section
          className={`lg:hidden flex flex-col items-start justify-start w-[100%] pt-[10px]`}
        >
          <Link
            to="/swap"
            className="py-2 px-3 bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 hover:dark:bg-opacity-10
         w-[100%] cursor-pointer dark:text-white opacity-50 rounded-lg"
          >
            Swap
          </Link>
          <Link
            to="/liquidity"
            className="py-2 px-3 mt-[10px] bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 hover:dark:bg-opacity-10
         w-[100%] cursor-pointer dark:text-white opacity-50 rounded-lg"
          >
            Liquidity
          </Link>
        </section>
      )}
    </header>
  );
};

export default Header;
