import "./App.css";
import { useState } from "react";
import { Home, Swap, Brandkit, Security, Documentation, Liquidity, Tokenlist, Connect, Join, Layout, Incentivized } from "./Components";
import { HashRouter, Routes, Route } from "react-router-dom";
import { GatekeeperProvider } from "./context/Gatekeeper";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import {
  base, arbitrum, mainnet, polygon, optimism
} from "./util/chains.js";

const { REACT_APP_PROJECT_ID } = process.env;

const metadata = {
  name: "Aerodrome Finance",
  description:
    "Aerodrome Finance: The central liquidity hub on Base network.", //fix
  url: "'https://www.aerodrome.finance/",
  icons: [
    "https://aerordrome.finance/aerodrome.svg", //fix
  ],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 8453 }),
  chains: [base, arbitrum, mainnet, polygon, optimism],
  projectId: REACT_APP_PROJECT_ID,
  theme: "dark",
});


function App() {
  return (
    <HashRouter>
      <GatekeeperProvider>
        <RoutesContext />
      </GatekeeperProvider>
    </HashRouter>
  );
}

function RoutesContext() {
  const [theme, setTheme] = useState("dark");
  return(
    <Routes>
      <Route path="/" element={<Layout theme={theme} setTheme={setTheme} />}>
        <Route path="/" element={<Home theme={theme} setTheme={setTheme} />} />
        <Route path="/swap" element={<Swap theme={theme} setTheme={setTheme} />}/>
        <Route path="/incentivized" element={<Incentivized theme={theme} setTheme={setTheme} />}/>
        <Route path="/join" element={<Join theme={theme} setTheme={setTheme} />}/>
        <Route path="/connect" element={<Connect theme={theme} setTheme={setTheme} />}/>
        <Route path="/liquidity" element={<Liquidity theme={theme} setTheme={setTheme} />}/>
        <Route path="/brandkit" element={<Brandkit theme={theme} setTheme={setTheme} />}/>
        <Route path="/security" element={<Security theme={theme} setTheme={setTheme} />}/>
        <Route path="/documentation" element={<Documentation theme={theme} setTheme={setTheme} />}/>
        <Route path="/tokenlist" element={<Tokenlist theme={theme} setTheme={setTheme} />}/>
      </Route>
    </Routes>
  )
}

export default App;
