import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { BKlong } from "../Longs";

const Brandkit = ({ theme, setTheme }) => {
  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <section className={`${theme === "dark" ? "bg-[#151b33]" : "bg-[#ebe8e1]"} min-h-[100vh]`}>
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />
      <section className={` ${theme === "light" && "bg-[#ebe8e1]"} px-[30px] lg:px-[50px]`}>
        <Header theme={theme} />
      </section>

      <section className={`flex flex-col items-center justify-center pt-[50px] px-[20px] lg:px-[100px] min-h-[70vh]`}>
        <BKlong />
      </section>

      <section  className={` ${theme === "dark" ? "bg-[#151b33] text-white" : "bg-[#ebe8e1]"} pt-[50px] `}>
        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Brandkit;
