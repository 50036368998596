import { useState } from "react";
import { Link } from "react-router-dom";
const SClong = () => {
    const [show, setShow] = useState(false);
  return (
    <section class="w-[100%] text-black dark:text-white" id="intro">

    <div class="lg:flex gap-20 px-4 py-6 lg:pt-12 items-start" >
    <div class="sticky top-0 sm:top-10 z-40 lg:w-3/12 pb-12">
        <div className="block lg:hidden bg-white dark:bg-gray-900 p-4 rounded-lg -mx-2 flex items-center justify-between">
          <div className="pl-2 opacity-30 dark:text-white">Security</div>
          <div className="w-fit" data-testid="flowbite-tooltip-target">
            <button
              type="button"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
            onClick={() => {
                setShow(!show)
            }}
            >
              <span className="flex items-center rounded-md text-xs px-2 py-1">
                Quick Navigation
                <i class='bx bx-chevron-down' ></i>
              </span>
            </button>
          </div>
          {
            show && 
          <div
            data-testid="flowbite-tooltip"
            
            className="z-30 w-fit rounded-lg divide-y divide-gray-100 shadow-sm transition-opacity duration-100 
             bg-white text-gray-900 dark:bg-gray-900 border border-neutral-200 dark:border-gray-700
             dark:border-opacity-70 dark:text-white rounded"
            style={{
                position: "absolute",
                top: "50px",
                left: "8px"
            }}
            id=":re:"
            role="tooltip"
          >
            <div className="text-sm text-gray-700 dark:text-gray-200">
              <ul className="py-1">
                <li className="flex items-center justify-start mx-2 mt-2 mb-1 py-2 px-2 text-sm text-gray-700 cursor-pointer hover:bg-neutral-100 hover:bg-opacity-50 dark:text-gray-200 dark:hover:bg-gray-850 dark:hover:bg-opacity-50 dark:hover:text-white">
                  <div className="w-64 sm:w-96">
                    <div className="space-y-1">
                      <div className="opacity-30 text-sm pb-2">Security:</div>
                      <button href="#" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 dark:text-white text-[black] rounded px-3 py-1.5">
                          Overview
                        </div>
                      </button>
                      <button href="#contracts" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 dark:text-white text-[black] rounded px-3 py-1.5">
                          Contracts
                        </div>
                      </button>
                      <button href="#emergency" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 dark:text-white text-[black] rounded px-3 py-1.5">
                          Emergency Council
                        </div>
                      </button>
                      <div className="opacity-20 text-sm pb-2 pt-8">Docs</div>
                      <Link to="/documentation" className="block">
                        <div className="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10
                        text-black dark:text-white  hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Documentation
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-plus"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5v14"></path>
                          </svg>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          }
        </div>

        <div className="hidden lg:block">
          <div className="space-y-1">
            <div className="opacity-30 text-sm pb-2 dark:text-white">Security:</div>
            <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("intro");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
              <div className="bg-neutral-200 hover:bg-white dark:text-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Overview
              </div>
            </button>
            <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("contracts");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
              <div className="bg-neutral-200 hover:bg-white dark:text-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Contracts
              </div>
            </button>
                                      <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("emergency");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
              <div className="bg-neutral-200 hover:bg-white dark:text-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Emergency Council
              </div>
            </button>
            <div className="opacity-20 text-sm pb-2 pt-8 dark:text-white text-black">Docs</div>
                                      <button className="block w-[100%] text-left" 
            onClick={() => {
                    const element = document.getElementById("docs");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}>
              <div className="flex justify-between items-center text-[black] dark:text-white bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Documentation
                <i class='bx bx-plus' ></i>
              </div>
            </button>
          </div>
        </div>
      </div>
      
      <div className="w-full lg:w-7/12">
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          Security Overview
        </h1>
        <p className="mb-4 dark:text-white text-[black]]">
          Aerodrome Finance inherits the contract architecture and full security
          maintenance from Velodrome V2. Velodrome V2 has been audited and runs
          button bug-bounty program.
        </p>
        <p className="mb-4 dark:text-white text-[black]]">
          To review the current state of the smart-contracts and the bug bounty
          program, please refer to the Velodrome security page.
        </p>
        <a
          href="https://velodrome.finance/security"
          className="text-gray-900 bg-white border border-white hover:bg-white hover:text-[#0052ff] disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-[#0052ff] dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg my-8 w-1/2 m-auto"
        >
          <span className="flex items-center rounded-md text-sm px-3 py-1.5">
            <strong>Velodrome on Security</strong>
          </span>
        </a>
        <p className="mb-4 dark:text-white text-[black]]">
          We would like to remind our users that security audits do not
          eliminate risks completely and that every user should read and agree
          to our legal disclaimer before they continue using this website.
        </p>
        <p className="mb-4 dark:text-white text-[black]]">
          For security reports, please reach out to us on{" "}
          <a
            className="text-[#0052ff]
underline hover:no-underline underline-offset-2"
            href="https://discord.gg/aerodrome"
          >
            Discord
          </a>
          , or to the contacts provided on our Github page.
        </p>
        <div id="emergency"></div>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Emergency Council
        </h2>
        <p className="mb-4 dark:text-white text-[black]]">
          To support the health of the protocol and ecosystem, there is button
          Council consisting of members from the Aerodrome team and prominent
          figures from within the Optimism and Base community.
        </p>
        <p className="mb-4 dark:text-white text-[black]]">
          The Council can be spawned in case of emergency situations or by
          passing button governance proposal that requires the council's help to be
          executed.
        </p>
        <p className="mb-4 dark:text-white text-[black]]">
          The Council address is at{" "}
          <strong>
            <code>0x99249b10593fCa1Ae9DAE6D481 <br />9F1A6dae5C013D</code>
          </strong>{" "}
          and it:
        </p>
        <ul className="mb-8 list-inside list-disc dark:text-white text-[black]">
          <li>
            can set button new <code>emergencyCouncil</code>
          </li>
          <li>can kill button gauge</li>
          <li>can revive button gauge</li>
          <li>can set button custom name or symbol for button pool</li>
          <li>
            can activate or deactivate (m)veNFTs (superseded by the governance
            once enabled)
          </li>
        </ul>
        <div id="contracts"></div>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Contract Addresses
        </h2>
        <br />

        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 overflow-scroll">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400 bg-[#0052ff] text-white dark:bg-50">
              <tr>
                <th className="px-6 py-3">Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">AERO</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x940181a94A35A4569E4529A3CDfB74e38FD98631">
                      0x940181a94A35A4569E4529A3CDfB74e38FD98631
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">AirdropDistributor</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xE4c69af018B2EA9e575026c0472B6531A2bC382F">
                      0xE4c69af018B2EA9e575026c0472B6531A2bC382F
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">ArtProxy</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xE9992487b2EE03b7a91241695A58E0ef3654643E">
                      0xE9992487b2EE03b7a91241695A58E0ef3654643E
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">FactoryRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x5C3F18F06CC09CA1910767A34a20F771039E37C0">
                      0x5C3F18F06CC09CA1910767A34a20F771039E37C0
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Forwarder</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x15e62707FCA7352fbE35F51a8D6b0F8066A05DCc">
                      0x15e62707FCA7352fbE35F51a8D6b0F8066A05DCc
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">GaugeFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x35f35cA5B132CaDf2916BaB57639128eAC5bbcb5">
                      0x35f35cA5B132CaDf2916BaB57639128eAC5bbcb5
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">ManagedRewardsFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xFdA1fb5A2a5B23638C7017950506a36dcFD2bDC3">
                      0xFdA1fb5A2a5B23638C7017950506a36dcFD2bDC3
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Minter</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xeB018363F0a9Af8f91F06FEe6613a751b2A33FE5">
                      0xeB018363F0a9Af8f91F06FEe6613a751b2A33FE5
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">PoolFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x420DD381b31aEf6683db6B902084cB0FFECe40Da">
                      0x420DD381b31aEf6683db6B902084cB0FFECe40Da
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">RewardsDistributor</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x227f65131A261548b057215bB1D5Ab2997964C7d">
                      0x227f65131A261548b057215bB1D5Ab2997964C7d
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Router</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xcF77a3Ba9A5CA399B7c97c74d54e5b1Beb874E43">
                      0xcF77a3Ba9A5CA399B7c97c74d54e5b1Beb874E43
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Voter</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x16613524e02ad97eDfeF371bC883F2F5d6C480A5">
                      0x16613524e02ad97eDfeF371bC883F2F5d6C480A5
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VotingEscrow</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xeBf418Fe2512e7E6bd9b87a8F0f294aCDC67e6B4">
                      0xeBf418Fe2512e7E6bd9b87a8F0f294aCDC67e6B4
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VotingRewardsFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x45cA74858C579E717ee29A86042E0d53B252B504">
                      0x45cA74858C579E717ee29A86042E0d53B252B504
                    </button>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Relay Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">RelayFactoryRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xD308aBCe663302d3b86b36d332CEFd8A4F62C5Ed">
                      0xD308aBCe663302d3b86b36d332CEFd8A4F62C5Ed
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">KeeperRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0x08724A9b94767a0BB9b539d3133Ac7A6AF9F283c">
                      0x08724A9b94767a0BB9b539d3133Ac7A6AF9F283c
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">OptimizerRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4 dark:text-white text-[black]]">
                    <button href="https://basescan.org/address/0xabE4cbcE47707D7A74bF6F1a343FF2c92267D3ea">
                      0xabE4cbcE47707D7A74bF6F1a343FF2c92267D3ea
                    </button>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </section>
  );
};

export default SClong;
