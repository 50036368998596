

const TL = [
    {
        id: 1,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
    {
        id: 2,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
    {
        id: 3,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
    {
        id: 1,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
    {
        id: 2,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
    {
        id: 3,
        image1: "./images/t1.svg",
        image2: "./images/t2.svg",
        name: "vAMM",
        pool: "Volatile",
        percent: "0.3",
        value: "56,645,535",
        apr: "52",
        t1: "WETH",
        t2: "USDC",
        t1vol: "619",
        t2vol: "290",
        vol: "4,535,234",
        fees: "12,344.56",
        t1fees: "1.42",
        t2fees: "6,312.45",
        pb1: "9,345.99",
        pb2: "12,898.65"
    },
]

export default TL