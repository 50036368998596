import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Liqrouting } from "../Longs";

const Swap = ({ theme, setTheme }) => {
  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [baseAmount, setBaseAmount] = useState("");

  return (
    <section
      className={`${theme === "dark" ? "bg-[#151b33]" : "bg-[#ebe8e1]"}
       min-h-[100vh]`}
    >
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />

      <section
        className={` ${
          theme === "light" && "bg-[#ebe8e1]"
        } px-[30px] lg:px-[50px]`}
      >
        <Header theme={theme} />
      </section>

      <section
        className={`flex flex-col items-center justify-center pt-[50px] px-[20px] lg:px-[100px]
 min-h-[70vh]`}
      >
        <section className="flex flex-col lg:flex-row items-center justify-between w-[100%]">
          <div class="lg:flex gap-6 lg:w-[50%] w-[100%]">
            <div className="w-[100%] lg:w-[100%] p-6 sm:p-12 dark:bg-[#1e233d] bg-gray-50 bg-opacity-70 rounded-lg shadow flex flex-col ">
              <div>
                <div>
                  <div class="flex flex-col gap-1.5 sm:flex-row justify-between items-baseline mb-3">
                    <div class="text-sm text-gray-700 dark:text-gray-300">
                      Swap
                    </div>
                    <div class="text-xs opacity-80 text-right flex gap-3 items-center pb-1"></div>
                  </div>
                  <div class="flex">
                    <span class="inline-flex items-center px-3 hover:bg-neutral-50 dark:hover:bg-gray-850 rounded-l-md text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 border border-r-0 border-gray-300 dark:border-gray-600">
                      <div class="w-28 flex items-center justify-between cursor-pointer">
                        <div class="flex gap-3 items-center">
                          <img
                            src="https://assets.smold.app/api/token/8453/0x940181a94A35A4569E4529A3CDfB74e38FD98631/logo.svg"
                            class="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                          />
                          <div class="text-sm truncate">AERO</div>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-chevron-down"
                        >
                          <path d="m6 9 6 6 6-6"></path>
                        </svg>
                      </div>
                    </span>
                    <div class="relative w-full">
                      <input
                        class="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30
                         bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 
                         focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 
                         outline-0 rounded-r-lg p-2.5 text-sm"
                        required=""
                        placeholder="0.0"
                        onChange={(e) => {
                          setBaseAmount(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              
              <div class="mt-10 mb-6">
                <div class="border-t border-[#eff0f3] dark:border-gray-700 dark:border-opacity-50 
                flex justify-center text-gray-600 dark:text-gray-400 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="-mt-3 cursor-pointer rounded-lg bg-[#eff0f3] dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-1.5"
                  >
                    <path d="m21 16-4 4-4-4"></path>
                    <path d="M17 20V4"></path>
                    <path d="m3 8 4-4 4 4"></path>
                    <path d="M7 4v16"></path>
                  </svg>
                </div>
              </div>


              <div class="mb-1">
                <div>
                  <div class="flex flex-col gap-1.5 sm:flex-row justify-between items-baseline mb-3">
                    <div class="text-sm text-gray-700 dark:text-gray-300">
                      For
                    </div>
                    <div class="text-xs opacity-80 text-right flex gap-3 items-center pb-1"></div>
                  </div>
                  <div class="flex">
                    <span class="inline-flex items-center px-3 hover:bg-neutral-50 dark:hover:bg-gray-850 rounded-l-md text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 border border-r-0 border-gray-300 dark:border-gray-600">
                      <div class="w-28 flex items-center justify-between cursor-pointer">
                        <div class="flex gap-3 items-center">
                          <img
                            src="https://assets.smold.app/api/token/8453/0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913/logo.svg"
                            class="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                          />
                          <div class="text-sm truncate">USDC</div>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-chevron-down"
                        >
                          <path d="m6 9 6 6 6-6"></path>
                        </svg>
                      </div>
                    </span>
                    <div class="relative w-full">
                      <input
                        class="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 outline-0 rounded-r-lg p-2.5 text-sm"
                        required=""
                        disabled=""
                        value="1.553796"
                      />
                    </div>
                  </div>
                </div>

                {baseAmount !== "" && <Liqrouting />}
              </div>
            </div>
          </div>
          {baseAmount !== "" ? (
            <div className="w-[100%] lg:w-[47%] mt-[20px] lg:mt-[0px] p-6 sm:p-12 dark:bg-[#1e233d] bg-gray-50 bg-opacity-70 dark:bg-opacity-50 rounded-lg shadow flex">
              <div class="w-full min-h-[400px] ">
                <div class="text-xl mb-4 dark:text-white text-black" >Swap</div>
                <div class="flex-col">
                  <div class="pt-2 pl-2">
                    <ol
                      data-testid="timeline-component"
                      class="relative border-l border-gray-200 dark:border-gray-700"
                    >
                      <li data-testid="timeline-item" class="mb-10 ml-6 !mb-6">
                        <div data-testid="timeline-point" class="">
                          <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                    <img 
                      src="./images/calc.svg"
                    />
                          </span>
                        </div>
                        <div data-testid="timeline-content" class="">
                          <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            <div class="flex flex-col sm:flex-row gap-2 sm:items-center py-1">
                              Exchange rate found...{" "}
                              <a
                                href=""
                                color="light"
                                class="underline hover:no-underline"
                              >
                                <div class="flex gap-2 items-center">
                                  Refresh
                                </div>
                              </a>
                            </div>
                            <div class="mt-2 flex gap-2 items-center">
                              <strong>1 AERO</strong>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="lucide lucide-arrow-left-right"
                              >
                                <path d="M8 3 4 7l4 4"></path>
                                <path d="M4 7h16"></path>
                                <path d="m16 21 4-4-4-4"></path>
                                <path d="M20 17H4"></path>
                              </svg>
                              <div
                                class="inline cursor-pointer"
                                data-testid="flowbite-tooltip-target"
                              >
                                <span class="tracking-wider">
                                  0.7769
                                  <span class="opacity-60">&nbsp;USDC</span>
                                </span>
                              </div>
                              <div
                                data-testid="flowbite-tooltip"
                                tabindex="-1"
                                class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                // style="position: absolute; top: 52px; left: 104.266px;"
                                id=":rm5:"
                                role="tooltip"
                              >
                                <div class="relative z-20">~$0.77693</div>
                                <div
                                  class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                  data-testid="flowbite-tooltip-arrow"
                                  // style="top: -4px; left: 27.5px;"
                                >
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </time>
                        </div>
                      </li>
                      <li data-testid="timeline-item" class="mb-10 ml-6 !mb-4">
                        <div data-testid="timeline-point" class="">
                          <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
            <img 
              src="./images/plus.svg"
            />
                          </span>
                        </div>
                        <div data-testid="timeline-content" class="">
                          <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            <strong>1.0%</strong> slippage applied...{" "}
                            <span class="underline hover:no-underline cursor-pointer">
                              Adjust
                            </span>
                          </time>
                        </div>
                      </li>
                      <li data-testid="timeline-item" class="mb-10 ml-6 !mb-4">
                        <div data-testid="timeline-point" class="">
                          <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
<img 
  src="./images/min.svg"
/>
                          </span>
                        </div>
                        <div data-testid="timeline-content" class="">
                          <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            Minimum received 1.54 USDC
                          </time>
                        </div>
                      </li>
                      <li data-testid="timeline-item" class="mb-10 ml-6 !mb-4">
                        <div data-testid="timeline-point" class="">
                          <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                          <i class='bx bx-check text-[#0e9f6e]' ></i>
                          </span>
                        </div>
                        <div data-testid="timeline-content" class="">
                          <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            {" "}
                            0.07076% price impact is safe
                          </time>
                        </div>
                      </li>
                      <li
                        data-testid="timeline-item"
                        class="mb-10 ml-6 !mt-6 !mb-0"
                      >
                        <div data-testid="timeline-point" class="">
                          <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
<img 
  src="./images/wal.svg"
/>
                          </span>
                        </div>
                        <div data-testid="timeline-content" class="">
                          <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            Please connect your wallet to continue...
                          </time>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-[100%] lg:w-[47%] mt-[20px] lg:mt-[0px] p-6 sm:p-12 dark:bg-[#1e233d] bg-gray-50 bg-opacity-70 dark:bg-opacity-50 rounded-lg shadow flex">
              <div className="w-full">
                <div className="text-xl mb-4 dark:text-white">Swap</div>
                <div className="flex-col">
                  <div className="pt-2 pl-2">
                    <ol
                      data-testid="timeline-component"
                      className="relative border-l border-gray-200 dark:border-gray-700"
                    >
                      <li data-testid="timeline-item" className="mb-10 ml-6">
                        <div data-testid="timeline-point" className="">
                          <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                            <div className="text-xs font-bold dark:text-white">
                              1
                            </div>
                          </span>
                        </div>
                        <div data-testid="timeline-content" className="">
                          <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            Start by selecting the token to{" "}
                            <strong className="font-bold">Swap</strong> from and
                            the amount you want to exchange
                          </time>
                        </div>
                      </li>
                      <li data-testid="timeline-item" className="mb-10 ml-6">
                        <div data-testid="timeline-point" className="">
                          <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                            <div className="text-xs font-bold dark:text-white">
                              2
                            </div>
                          </span>
                        </div>
                        <div data-testid="timeline-content" className="">
                          <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            Pick the token you want to exchange{" "}
                            <strong className="font-bold">For</strong>
                          </time>
                        </div>
                      </li>
                      <li
                        data-testid="timeline-item"
                        className="mb-10 ml-6 !mb-0"
                      >
                        <div data-testid="timeline-point" className="">
                          <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                            <div className="text-xs font-bold dark:text-white">
                              3
                            </div>
                          </span>
                        </div>
                        <div data-testid="timeline-content" className="">
                          <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                            The quote will be ready in a moment!
                          </time>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <img
          src={` ${
            theme === "dark"
              ? "https://aerodrome.finance/svg/plane-light.svg"
              : "https://aerodrome.finance/svg/plane.svg"
          }`}
          className="mt-[-50px]"
        />
      </section>

      <section
        className={` ${
          theme === "dark" ? "bg-[#151b33] text-white" : "bg-[#ebe8e1]"
        } pt-[50px] `}
      >
        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Swap;
