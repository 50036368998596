
const Legal = [
    {
        id: 1,
        title: "Usage risks",
        body1: "The Website will not be responsible for any losses, damages, or claims arising from events falling within the scope of events like, but not limited to: mistakes made by the user (e.g., payments sent to wrong addresses), software problems of the Website or any related software or service (e.g., malware or unsafe cryptographic libraries), technical failures (e.g., hardware wallets malfunction), security problems experienced by the user (e.g., unauthorized access to wallets), actions or inactions of third parties (e.g., bankruptcy of service providers, information security attacks on service providers, and fraud conducted by third parties).",
        body2: 'To access the Website or some of the resources it offers, you may be asked to provide an Ethereum wallet address "Wallet". Our Website does not collect, nor store this information.'
    },
    {
        id: 2,
        title: "Investment risks",
        body1: "The investment in cryptocurrencies involves a high degree of risk and can lead to loss of money and prices having large range fluctuations. The information published on the Website cannot and does not guarantee that amounts invested will not be lost.",
        body2: "You are responsible for understanding all risks, doing Your own due diligence, and making Your own decision on how to interface with the Website."
    },
    {
        id: 3,
        title: "Compliance with tax obligations",
        body1: "The users of the Website are solely responsible to determinate what, if any, taxes apply to their cryptocurrency holdings. The owners of, or contributors to, the Website are NOT responsible for determining the taxes that apply to user transactions."
    },
    {
        id: 4,
        title: "No warranties",
        body1: 'The Website is provided on an "as is" basis without any warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or that availability, accessibility, or use of the Website and/or any content, data, materials or services provided hereon will be uninterrupted or error-free.',
        body2: "Website functionality is not guaranteed and could be disabled fully or in part without prior notice."
    },
    {
        id: 5,
        title: "Security",
        body1: "Security audits don't eliminate risks completely. The Website is not guaranteed to be secure or free from bugs or viruses."
    },
    {
        id: 6,
        title: "Limitation of liability",
        body1: "To the fullest extent allowed by applicable law, in no event shall the owners of, or contributors to, the Website be liable for any damages of any kind, including, but not limited to, indirect, special, incidental, punitive, or consequential damages of any kind, including damages for loss of use, loss of profits, business interruption, loss of goodwill, or loss of data arising out of or in any way connected with the use of the Website."
    },
]

export default Legal