const ankrKey = process.env.REACT_APP_ANKR_KEY;

export const arbitrum = {
  chainId: 42161,
  name: "Arbitrum One",
  currency: "ETH",
  explorerUrl: "https://arbiscan.io/",
  rpcUrl: `https://rpc.ankr.com/arbitrum/`,
};

export const avalanche = {
  chainId: 43114,
  name: "Avalanche",
  currency: "AVAX",
  explorerUrl: "https://snowtrace.io",
  rpcUrl: `https://rpc.ankr.com/avalanche/`,
};

export const base = {
  chainId: 8453,
  name: "Base",
  currency: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: `https://rpc.ankr.com/base/`,
};

export const bsc = {
  chainId: 56,
  name: "BNB Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: `https://rpc.ankr.com/bsc/`,
};

export const fantom = {
  chainId: 250,
  name: "Fantom",
  currency: "FTM",
  explorerUrl: "https://ftmscan.com",
  rpcUrl: `https://rpc.ankr.com/fantom/`,
};

export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  //rpcUrl: 'https://cloudflare-eth.com',
  rpcUrl: `https://rpc.ankr.com/eth/`,
};

export const optimism = {
  chainId: 10,
  name: "Optimism",
  currency: "ETH",
  explorerUrl: "https://optimistic.etherscan.io/",
  rpcUrl: `https://rpc.ankr.com/optimism/`,
};

export const polygon = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: `https://rpc.ankr.com/polygon/`,
};

export const pulsechain = {
  chainId: 369,
  name: "PulseChain",
  currency: "PLS",
  explorerUrl: "https://scan.pulsechain.com",
  rpcUrl: `https://rpc.pulsechain.com`,
};

export const zkSync = {
  chainId: 324,
  name: "zkSync Era",
  currency: "ETH",
  explorerUrl: "https://explorer.zksync.io",
  rpcUrl: `https://rpc.ankr.com/zksync_era/`,
};

/*export const chains = {
  42161: 'arb',
  43114: 'avax',
  8453: 'base',
  56: 'bsc',
  1: 'eth',
  10: 'op',
  137: 'matic',
  369: 'pls',
  324: 'era',
  250: 'ftm
} */

export const chains = {
  42161: {
    dbankId: "arb",
    hexChainId: "0xa4b1",
    name: "Arbitrum One",
    network: "arbitrum",
    currency: "ETH",
    explorerUrl: "https://arbiscan.io/",
    rpcUrl: `https://rpc.ankr.com/arbitrum/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  43114: {
    dbankId: "avax",
    hexChainId: "0xa86a",
    name: "Avalanche",
    network: "avalanche",
    currency: "AVAX",
    explorerUrl: "https://snowtrace.io/",
    rpcUrl: `https://rpc.ankr.com/avalanche/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  8453: {
    dbankId: "base",
    hexChainId: "0x2105",
    name: "Base",
    network: "base",
    currency: "ETH",
    explorerUrl: "https://basescan.org/",
    rpcUrl: `https://rpc.ankr.com/base/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  56: {
    dbankId: "bsc",
    hexChainId: "0x38",
    name: "BNB Smart Chain",
    network: "bsc",
    currency: "AVAX",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: `https://rpc.ankr.com/bsc/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  1: {
    dbankId: "eth",
    hexChainId: "0x1",
    name: "Ethereum",
    network: "mainnet",
    currency: "ETH",
    explorerUrl: "https://etherscan.io/",
    //rpcUrl: 'https://cloudflare-eth.com',
    rpcUrl: `https://rpc.ankr.com/eth/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  10: {
    dbankId: "op",
    hexChainId: "0xa",
    name: "Optimism",
    network: "optimism",
    currency: "ETH",
    explorerUrl: "https://optimistic.etherscan.io/",
    rpcUrl: `https://rpc.ankr.com/optimism/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  137: {
    dbankId: "matic",
    hexChainId: "0x89",
    name: "Polygon",
    network: "polygon",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    rpcUrl: `https://rpc.ankr.com/polygon/${ankrKey}`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  369: {
    dbankId: "pls",
    hexChainId: "0x171",
    name: "PulseChain",
    network: "pulsechain",
    currency: "PLS",
    explorerUrl: "https://scan.pulsechain.com/",
    rpcUrl: `https://rpc.pulsechain.com`,
    contrAdd: '0x3B243cD860ebbe0CD04C168C2b7515B0f9B6018F'
  },
  324: {
    dbankId: "era",
    hexChainId: "0x144",
    name: "zkSync Era",
    network: "zkSync",
    currency: "ETH",
    explorerUrl: "https://explorer.zksync.io/",
    rpcUrl: `https://rpc.ankr.com/zksync_era/${ankrKey}`,
  },
  250: {
    dbankId: "ftm",
    hexChainId: "0xfa",
    name: "Fantom",
    network: "fantom",
    currency: "FTM",
    explorerUrl: 'https://ftmscan.com/',
    rpcUrl: `https://rpc.ankr.com/fantom/${ankrKey}`,
    contrAdd: '0x421b1061604Cc8C3F79cC3914a4C9B06898ea995' //not phase3
}
};
