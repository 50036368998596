import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Joinlong = ({ theme }) => {
  return (
    <section className="flex flex-col items-center">
      <div class="text-center space-y-8 pt-20 lg:pb-32">
        <div class="text-sm uppercase dark:text-white ">Getting Started On</div>
        <h1 class="text-5xl font-bold dark:text-white ">
          Aerodrome
          <br />
          Finance
        </h1>
      </div>

      <section className="flex flex-row items-start justify-between">
        <div class="sm:w-2/3 lg:w-[30%] pb-20 flex flex-col  gap-8 items-center ">
          <div class="text-center px-8 lg:px-0 my-16 lg:my-0 text-lg dark:text-white">
            Aerodrome is a <abbr title="Decentralized Exchange">DEX</abbr> that
            combines the best of Curve, Convex, and Uniswap V2 into a cohesive{" "}
            <abbr title="Automated Market Maker">AMM</abbr> designed to serve as{" "}
            <span class="font-sans uppercase italic tracking-tight font-extrabold text-[#0052ff] pb-8">
              BASE
            </span>{" "}
            network's liquidity layer.
          </div>
          <button
            type="button"
            class="text-white bg-[#0052ff] border border-transparent hover:bg-opacity-90 dark:bg-primary dark:hover:bg-opacity-90 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg mx-auto xl:mx-0"
          >
            <span class="flex items-center rounded-md text-base px-5 py-2.5">
              Token Listing Request
            </span>
          </button>
        </div>

        <Accordion defaultIndex={[0]} className="lg:w-[60%]">
          <AccordionItem>
            <h2>
              <AccordionButton>
              <button
                  class="flex w-full items-center justify-between first:rounded-t-lg py-5 px-5 text-left 
                  font-medium text-gray-500 dark:text-gray-400  bg-white
               dark:bg-[#1e233d] text-gray-900 dark:text-white"
                  type="button"
                >
                  <h2 class="" data-testid="flowbite-accordion-heading">
                    <span class="uppercase font-bold">Protocol Design</span>
                  </h2>
                  <AccordionIcon />
                </button>
                
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              className="flex flex-col items-start w-[100%]"
            >
              <div class="dark:text-white text-black py-8  bg-white rounded-b-lg p-4 dark:bg-[#11162b]">
                <p class="mb-4">
                  Aerodrome Finance uses <strong>AERO</strong> as a utility
                  token and <strong>AERO</strong> as governance token.
                </p>
                <p class="mb-4">
                  <strong>AERO</strong> is used for rewarding liquidity
                  providers through emissions.
                  <strong>veAERO</strong> is used for governance. Any{" "}
                  <strong>AERO</strong> holder can vote-escrow their tokens for
                  up to 4 years and receive a <strong>veAERO</strong> NFT in
                  exchange.
                </p>
                <p class="mb-4">
                  <strong>veAERO</strong> holders vote every epoch (weekly) on
                  which pools receive <strong>AERO</strong>
                  emissions. In return, voters receive 100% of the fees and
                  incentives the pool collects. Protocols looking to incentivize
                  liquidity can bribe <strong>veAERO</strong>
                  voters (i.e., deposit token rewards for voters of a pool) and
                  accumulate
                  <strong>veAERO</strong> to vote directly.
                </p>
              </div>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem className="mt-[-10px]">
            <h2>
              <AccordionButton>
                <button
                  class="flex w-full items-center justify-between py-5 px-5 text-left 
                  font-medium text-gray-500 dark:text-gray-400 bg-white
               dark:bg-[#1e233d] text-gray-900 dark:text-white"
                  type="button"
                >
                  <h2 class="" data-testid="flowbite-accordion-heading">
                    <span class="uppercase font-bold">Liquidity Market</span>
                  </h2>
                  <AccordionIcon />
                </button>
                
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="">
              <div class="dark:text-white text-black  bg-white py-8 rounded-b-lg p-4 dark:bg-[#11162b]">
                <p class="mb-4">
                  All tokens need liquidity on AMM dexes. Having more liquidity
                  for a token:
                </p>
                <ul class="mb-8 list-inside list-disc">
                  <li>
                    Allows people to trade more of it without incurring a high
                    price impact
                  </li>
                  <li>
                    Provides utility for tokens; holders will buy and hold
                    tokens for gains from providing liquidity
                  </li>
                </ul>
                <p class="mb-4">
                  Liquidity providers add liquidity by depositing equal values
                  of two tokens (one of which is usually a stable or ETH) into a
                  liquidity pool. Doing so carries impermanent loss risk, where
                  big price changes make depositors recoup less value than what
                  they’d have had if they’d just held the tokens. So they need
                  to be highly compensated to take on this risk. The thinking
                  is,
                  <em>
                    will the rewards make me better off than I think I risk
                    losing from impermanent loss?
                  </em>
                </p>
                <p class="mb-4">
                  Aerodrome rewards liquidity providers solely through AERO
                  emissions. AERO emitted to a liquidity pool is distributed
                  proportionally. If I provide more of the liquidity in an LP, I
                  get more AERO as rewards.
                </p>
                <p class="mb-4">
                  Aerodrome distributes a programmed amount of AERO to liquidity
                  providers across all liquidity pools. How much AERO goes to
                  each liquidity pool changes by a weekly vote. Voters are
                  veAERO holders — people who have acquired AERO and locked it
                  to receive a veAERO NFT, which gives them votes to distribute
                  to pools.
                </p>
                <p class="mb-4">
                  Voters have several potential reasons to vote for specific
                  pools and increase compensation for liquidity providers to
                  those pools. They might:
                </p>
                <ol class="mb-8 list-inside list-decimal">
                  <li>
                    want to get more liquidity for specific tokens for the
                    benefits mentioned above;
                  </li>
                  <li>
                    themselves be liquidity providers on those pools and want to
                    gain more for providing liquidity;
                  </li>
                  <li>want to get more direct rewards for their vote.</li>
                </ol>
                <p class="mb-4">
                  The third reason, <strong>direct rewards</strong>, is the most
                  common one. Aerodrome rewards votes in two ways: <em>fees</em>{" "}
                  and <em>incentives</em>.
                </p>
                <p class="mb-4">
                  <strong>Fees:</strong> Aerodrome charges trading fees; when
                  someone swaps tokens from a liquidity pool, some of the tokens
                  they trade are distributed to voters for that pool. More trade
                  volume translates to more fees distributed to voters, so
                  voters are incentivized to vote for pools they think will be
                  highly used.
                </p>
                <p class="mb-4">
                  <strong>Incentives:</strong> Anybody can incentivize votes for
                  a specific pool by depositing tokens to be distributed to
                  voters on that pool. Their thinking is: if I pay voters for
                  voting on that pool, I’ll get more votes on that pool and thus
                  more AERO emitted to that pool, which means more liquidity for
                  tokens on that pool. And then these tokens get the benefits
                  mentioned at the top: more trading, more (and larger)
                  participants, more token utility.
                </p>
                <blockquote class="my-4 border-l italic opacity-70 pl-4 text-gray-600 dark:text-gray-400">
                  <p class="mb-4">
                    Note: Voters can add incentives for pools they’re voting on.
                    They recoup some of their incentives by voting on that pool,
                    and they incentivize others to join in with them in voting
                    for that pool. Liquidity providers also can and add
                    incentives for pools they’re providing liquidity to, as
                    historically that pool gets a multiple of the incentive
                    value back in AERO emissions; they may receive more value
                    than they send as an incentive.
                  </p>
                </blockquote>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </section>

      <div class="text-center text-lg py-[50px]">
        <h1 class="text-5xl font-bold mb-4 dark:text-white">Aerodrome</h1>
        <div class="uppercase text-sm dark:text-white">In 3 Simple Steps</div>
      </div>
      <section className="flex flex-col lg:flex-row items-center justify-between">
        <div class="lg:w-[33%] flex gap-6 bg-white bg-opacity-20 dark:bg-white dark:bg-opacity-5 p-6 rounded-lg">
          <div class="grid place-content-between pl-2">
            <div>
              <div class="font-bold text-lg mb-2 dark:text-white">
                Token Integration
              </div>
              <div class="text-sm dark:text-white">
                You will need the token contract address and the logo. Add the
                token data in a pull request on the{" "}
                <a
                  href="https://github.com/SmolDapp/tokenAssets"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Tokenlist repo</u>
                </a>
                , or ask for help doing so on the{" "}
                <a
                  href="https://discord.gg/aerodrome"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Aerodrome Discord</u>
                </a>
                .
              </div>
            </div>
            <div class="flex mt-6">
              <a
                href="https://github.com/SmolDapp/tokenAssets"
                class="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
              >
                <span class="flex items-center rounded-md text-sm px-4 py-2">
                  Add token
                </span>
              </a>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke={`${theme === "dark" ? "white" : "black"}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="shrink-0"
          >
            <circle cx="12" cy="8" r="6"></circle>
            <path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11"></path>
          </svg>
        </div>

        <div class="lg:w-[33%] my-[10px] lg:my-[0px] flex gap-6 bg-white bg-opacity-20 dark:bg-white dark:bg-opacity-5 p-6 rounded-lg">
          <div class="grid place-content-between pl-2">
            <div>
              <div class="font-bold text-lg mb-2 dark:text-white">
                Create a Pair
              </div>
              <div class="text-sm dark:text-white">
                Deposit your tokens by creating a liquidity pool. You can stake
                the deposited tokens right away to start earning the emissions
                after the voting concludes.
              </div>
            </div>
            <div class="flex mt-6">
              <Link
                to="/connect"
                class="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
              >
                <span class="flex items-center rounded-md text-sm px-4 py-2">
                  Deposit
                </span>
              </Link>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke={`${theme === "dark" ? "white" : "black"}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="shrink-0"
          >
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
          </svg>
        </div>

        <div class="lg:w-[33%] flex gap-6 bg-white bg-opacity-20 dark:bg-white dark:bg-opacity-5 p-6 rounded-lg">
          <div class="grid place-content-between pl-2">
            <div>
              <div class="font-bold text-lg mb-2 dark:text-white">
                Add Incentives & Vote
              </div>
              <div class="text-sm dark:text-white">
                The liquidity pool can receive incentives and votes every week
                before Wednesday midnight{" "}
                <abbr title="Universal Time Coordinated timezone">UTC</abbr>.
                Consider adding incentives at least 4 hours before the deadline
                to get more voters.
              </div>
            </div>
            <div class="flex mt-6">
              <Link
                to="/incentivized"
                class="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
              >
                <span class="flex items-center rounded-md text-sm px-4 py-2">
                  Add Incentives
                </span>
              </Link>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke={`${theme === "dark" ? "white" : "black"}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="shrink-0"
          >
            <rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
          </svg>
        </div>
      </section>
    </section>
  );
};

export default Joinlong;
