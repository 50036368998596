import { useEffect } from "react";
import { Link1, Link2 } from "../Maps";
import React from "react";
import Mirrors from "./Mirrors";
import { Link } from "react-router-dom";

const Footer = ({ theme, setTheme, onOpen}) => {
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  };
  
  return (
    <footer
      className={`px-[20px] lg:px-[50px]
      py-[30px] dark:text-white
      `}
    >
      <section
        className="text-sm flex flex-row items-end lg:items-center justify-between border-solid 
        border-[#11162b0d] py-[20px] dark:border-[#ffffff0d] border-b-[1px]"
      >
        <div className="text-sm flex flex-col lg:flex-row items-start lg:items-center justify-between w-[40%] lg:w-[30%]">
          <div className="flex flex-col items-start lg:flex-row lg:items-center justify-between w-[30%]">
            <a
              className="hover:underline text-[14px] font-[400]"
              href="https://medium.com/@AerodromeFi"
            >
              Articles
            </a>
            <a
              className="hover:underline text-[14px] font-[400]"
              href="https://discord.gg/aerodrome"
            >
              Support
            </a>
          </div>

          <div className="flex flex-row items-start  lg:items-center flex-wrap justify-start lg:justify-between pt-[40px] lg:pt-[0px] w-[100%] lg:w-[50%]">
            {Link1.map((us, id) => (
              <a key={id} href={us.link} className="m-[5px] lg:m-[0px]">
                {us.image}
              </a>
            ))}
          </div>
        </div>

        <button
          onClick={() => {
            handleTheme();
          }}
          className={`
          ${theme === "light" ? "hover:bg-[#343c5e33]" : "hover:bg-[#343c5e80]"}
          cursor-pointer p-[.75rem] rounded-[50%] z-50
          `}
        >
          <img
            src={theme === "dark" ? "./images/sun.svg" : "./images/moon.svg"}
          />
        </button>
      </section>

      <section className="text-sm flex flex-row items-start lg:items-center justify-between py-[20px]">
        <div className="text-sm flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-between w-[40%] lg:w-[30%]">
          {Link2.map((us, id) => (
            <Link
              key={id}
              to={us.link}
              className="hover:underline pt-[5px] lg:pt-[0px]"
            >
              {us.name}
            </Link>
          ))}
        </div>

            <Mirrors theme={theme} />
      </section>

      <section className="flex lg:flex-row flex-col items-start justify-between pt-[60px] lg:py-[40px]">
        <section className="flex flex-col lg:flex-row items-start lg:items-center justify-start">
          <h2 className="opacity-30 text-[14px]">
            2024 © Aerodrome Finance, v1.0.0beta+ebe35d
          </h2>
          <a
            className={`lg:pl-[10px] hover:underline ${
              theme === "dark" && "text-white"
            } opacity-50 font-[400] in text-[14px] cursor-pointer`}
            onClick={onOpen}
          >
            Legal Disclaimer
          </a>
        </section>

        <section className="flex flex-row items-center justify-start lg:pt-[0px] pt-[40px]">
          <h2 className="opacity-30 text-[14px]">A public good for</h2>
          <a className="pl-[10px] ">
            <img
              src="./images/blogo.svg"
              className="w-[60px] cursor-pointer hover:opacity-70"
            />
          </a>
        </section>
      </section>
    </footer>
  );
};

export default Footer;
