import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { TL } from "../Maps";
import { Link } from "react-router-dom";

const Liquidity = ({ theme, setTheme }) => {
  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [active, setActive] = useState("active");
  const choose = [
    {
        id: 1,
        name: "Active"
    },
    {
        id: 2,
        name: "Stable"
    },
    {
        id: 3,
        name: "Volatile"
    },
    {
        id: 4,
        name: "Incentivized"
    },
    {
        id: 5,
        name: "Low TLV"
    },
    {
        id: 6,
        name: "Participating"
    },
    {
        id: 7,
        name: "All Pools"
    },
  ];
  
  function select(e){
    setActive(e)
  }

  return (
    <section
      className={`${theme === "dark" ? "bg-[#151b33]" : "bg-[#ebe8e1]"}
       min-h-[100vh]`}
    >
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />

      <section
        className={` ${
          theme === "light" && "bg-[#ebe8e1]"
        } px-[30px] lg:px-[50px]`}
      >
        <Header theme={theme} />
      </section>

      <section
        className={`flex flex-col items-center justify-start pt-[50px] px-[20px] lg:px-[100px]
 min-h-[70vh]`}
      >
        <section className="flex flex-row items-start justify-between w-[100%] lg:h-[230px]">
          <div
            className={`w-[100%] lg:w-[70%] h-[100%] ${
              theme === "dark" && "hrr"
            } p-[20px] rounded-lg border-solid border-[1px] 
       border-white dark:border-opacity-30 `}
          >
            <img
              src="https://aerodrome.finance/svg/plane-light.svg"
              className="absolute w-[200px] lg:w-[500px] dark:flex hidden"
            />
            <img
              src="https://aerodrome.finance/svg/plane.svg"
              className="absolute w-[200px] lg:w-[500px] dark:hidden flex"
            />
            <h2 className="dark:text-white text-[black] leading-[1.5rem] text-[14px] pb-[20px]">
              Liquidity Providers (LPs) make low-slippage swaps <br /> possible.
              Deposit and Stake liquidity to earn AERO.
            </h2>

            <div className="flex flex-col lg:flex-row items-center jusitify-between w-[100%] lg:w-[60%]">
              <p className="bg-[#ffffff0d] rounded-[.25rem] text-[12px] in px-[1rem] py-[.5rem] dark:text-white text-[black]  w-[100%]">
                TVL ~$0.0
              </p>
              <p className="bg-[#ffffff0d] rounded-[.25rem] text-[12px] in px-[1rem] py-[.5rem] dark:text-white text-[black] w-[100%] my-[10px] lg:my-[0px] mx-[15px]">
                Fees ~$0.0
              </p>
              <p className="bg-[#ffffff0d] rounded-[.25rem] text-[12px] in px-[1rem] py-[.5rem] dark:text-white text-[black] w-[100%] ">
                Volume ~$0.0
              </p>
            </div>

            <div className="bg-[#eff0f30d] h-[1px] w-[100%] mt-[20px]"></div>

            <div className="pt-[20px] flex flex-col lg:flex-row items-start lg:items-center w-[100%] justify-between">
              <h2 className="dark:text-white opacity-50 dark:opacity-[.5] text-[12px] font-[400] w-[100%] lg:w-[70%]">
                There are currently 82 tokens listed.
                <Link className="underline px-[10px]" to="/tokenlist">View Tokens</Link>
                or
                <Link to="/connect" className="underline px-[10px]">List New Token</Link>
              </h2>

              <Link to="/connect" className="bg-[#0052ff] rounded-lg my-[20px] lg:my-[0px] text-[12px] text-white p-[12px] flex flex-row items-center">
                <img src="./images/pig.svg" className="pr-[10px]" />
                Deposit Liquidity
              </Link>
            </div>
          </div>

          <div
            className="bg-white dark:bg-[#5a62871a] w-[27%] h-[100%] p-[20px] rounded-lg border-solid border-[1px] dark:border-white dark:border-opacity-30 border-[#ffffff1a]
        hidden lg:flex flex-col items-start justify-between
        "
          >
            <h2 className="text-[#959cbf] font-[700] text-[16px]">
              How it works
            </h2>
            <h2 className="text-[#959cbf] font-[400] text-[12px]">
              The deeper the liquidity (TVL), the lower the slippage a pool will
              offer. LPs get AERO emissions, while veAERO lockers get the pool
              trading fees as an incentive to vote on the most productive pools.
            </h2>

            <a className="text-[#959cbf] font-[400] text-[14px] flex flex-row items-center ">
              <a className="text-[#959cbf] font-[400] text-[14px] underline flex flex-row items-center pr-[10px]">
                Read More
              </a>
              <i class="bx bx-help-circle no-underline"></i>
            </a>
          </div>
        </section>

        <section className="flex flex-col items-start w-[100%] pt-[50px]">
          <h2 className="dark:text-white text-[black] text-[1.25rem]">
            Liquidity Pools
          </h2>

          <section className="flex flex-col lg:flex-row items-center justify-between w-[100%] py-[20px]">
            <div className="w-[100%] lg:w-[70%] overflow-x-scroll flex-nowrap flex flex-row items-center">
            {
                choose.map((us, id) => (
                    <button
                className={` border-solid border-[0.5px] min-w-[110px] 
                lg:min-w-[100px] flex flex-row items-center justify-center
                ${active === us.name.toLowerCase() ? "bg-[#0052ff]  border-[#0052ff] text-white" : "bg-transparent border-[#6b7280]"}
                ${us.name === "Active" && "rounded-l-lg"} px-[10px]
                ${us.name === "All Pools" && "rounded-r-lg"}
                 min-w-[130px] dark:text-white py-[5px] `}
              key={id}
              onClick={() => {
                select(us.name.toLowerCase())
              }}
              >
              {us.name}
              </button>
                ))
            }
            </div>

            <div
              className="w-[100%] mt-[10px] lg:mt-[0px] lg:w-[25%] bg-transparent border-solid border-[1px] text-[#6b7280] border-[#6b7280] rounded-lg px-2.5 py-[5px]
            flex flex-row items-center"
            >
              <i class="bx bx-search text-[20px]"></i>
              <input
                type="text"
                placeholder="Symbol or Address"
                className="bg-transparent w-[90%] text-[black] dark:text-white pl-[20px]"
              />
            </div>
          </section>

          <section className="dark:bg-[#1e233d] hidden lg:flex flex-row items-center justify-between w-[100%] rounded-lg px-3 py-2.5 
          bg-white sticky top-10 ">
            <h2 className="uppercase w-[40%] opacity-40 text-[12px] dark:text-white ">
              Liquidity pool
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] items-end justify-end flex flex-row w-[15%]">
              apr
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] items-end justify-end flex flex-row w-[15%]">
              volume
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] items-end justify-end flex flex-row w-[15%]">
              fees
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] items-end justify-end flex flex-row w-[15%]">
              pool balance
            </h2>
          </section>

          <section className="lg:hidden dark:bg-[#1e233d] flex flex-row items-center justify-between sm:justify-start w-[100%] rounded-lg px-3 py-2.5 
          bg-white sticky top-0">
            <h2 className="uppercase opacity-40 text-[12px] dark:text-white">
              sort by:
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] sm:px-[10px]">
              apr
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] sm:px-[10px]">
              volume
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] sm:px-[10px]">
              fees
            </h2>
            <h2 className="uppercase text-[12px] underline decoration-dotted dark:text-white opacity-40 font-[700] sm:px-[10px]">
              pool balance
            </h2>
          </section>
        </section>

        <section className="hidden lg:flex flex-col items-start w-[100%] py-[10px]">
          {TL.map((us, id) => (
            <section
              key={id}
              className={`flex flex-row items-start w-[100%] border-solid bg-white mt-[1px] dark:border-[1px] dark:border-[#11162b] rounded-lg p-[20px]
                dark:bg-[#1e233d] justify-between ${us.id === us.length - 1 && "bg-red-500"} `}
            >
              <div className="flex flex-row items-start w-[40%] h-[100%]">
                <div className="flex flex-row items-center pr-[20px]">
                  <img src={us.image1} className="" />
                  <img src={us.image2} className="relative ml-[-10px]" />
                </div>
                <div>
                  <h2 className="text-black text-[14px] font-[700] dark:text-white">
                    {us.name}-{us.t1}/{us.t2}
                  </h2>
                  <h2 className="dark:text-[#959cbf] text-[12px]">
                    {us.pool} • {us.percent}% •{" "}
                  </h2>
                  <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px] pt-[20px]">
                    TVL{" "}
                    <h2 className="dark:text-[#959cbf] text-[12px] font-[700]">
                      ~${us.value}
                    </h2>
                  </h2>
                </div>
              </div>

              <div className="flex flex-col items-end justify-between h-[100%] w-[15%]">
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700]">
                  {us.apr}%
                </h2>
                <h2 className="dark:text-[#959cbf] invisible text-[12px] py-[5px] font-[400] flex flex-row items-center">
                  {us.t1vol}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="flex flex-row underline items-center dark:text-gray-600 text-[12px] ">
                  Add incentive
                </h2>
              </div>
              <div className="flex flex-col items-end justify-between h-[100%] w-[15%]">
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] py-[5px] font-[400] flex flex-row items-center">
                  {us.t1vol}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] font-[400] flex flex-row items-center">
                  {us.t2vol}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>
              </div>

              <div className="flex flex-col items-end justify-between h-[100%] w-[15%]">
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700]">
                  ~${us.fees}
                </h2>

                <h2 className="dark:text-[#959cbf] text-[12px] py-[5px] font-[400] flex flex-row items-center">
                  {us.t1fees}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] font-[400] flex flex-row items-center">
                  {us.t2fees}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>
              </div>
              <div className="flex flex-col items-end justify-between h-[100%] w-[15%]">
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700] flex flex-row items-center">
                  {us.pb1}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700] flex flex-row items-center">
                  {us.pb2}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>
                <a className="flex flex-row items-center rounded-lg border-solid border-[1px] dark:text-[#ffffff]
                 text-[black] text-[14px] px-[10px] py-[5px] mt-[10px] border-[#ffffff1a] cursor-pointer bg-transparent">
                    <img 
                        src="./images/pig.svg"
                        className="pr-[5px]"
                    />
                    Deposit
                </a>
              </div>
            </section>
          ))}
        </section>

        <section className="lg:hidden flex flex-col items-start w-[100%] py-[10px]">
          {TL.map((us, id) => (
            <section
              key={id}
              className="flex flex-col items-start w-[100%] border-solid dark:border-[1px] dark:border-[#11162b] rounded-lg p-[20px]
                dark:bg-[#1e233d] bg-white justify-between hover:opacity-70"
            >
              <div className="flex flex-col items-start w-[100%] h-[100%]">
              <div className="flex flex-row items-center">

                <div className="flex flex-row items-center pr-[20px]">
                  <img src={us.image1} className="" />
                  <img src={us.image2} className="relative ml-[-10px]" />
                </div>

                <div>
                <h2 className="text-black text-[14px] font-[700] dark:text-white">
                    {us.name}-{us.t1}/{us.t2}
                  </h2>
                  <h2 className="dark:text-[#959cbf] text-[12px] flex flex-row items-center">
                    {us.pool} • {us.percent}% •{" "} <i class='bx bx-info-circle pl-[5px]'></i>
                  </h2>
                </div>
              </div>

                <div>


                  <div className="flex flex-col items-start ">

                  <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px] pt-[10px]">
                    TVL{" "}
                    <h2 className="dark:text-[#959cbf] text-[12px] font-[700]">
                      ~${us.value}
                    </h2>
                  </h2>

                <div className="flex flex-row items-center pt-[5px]">
                <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px]">
                   APR{" "}
                    <h2 className="dark:text-[#959cbf] text-[12px] font-[700] pl-[5px]">
                      {us.apr}%
                    </h2>
                  </h2>
                  <h2 className="flex flex-row underline items-center dark:text-gray-600 text-[12px] pl-[15px]">
                  Add incentive
                </h2>
                </div>

                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start border-solid border-t-[1px] border-[#11162b] py-[15px] mt-[15px] h-[100%] w-[100%]">
              <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px]">
              Volume
              </h2>
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] py-[5px] font-[400] flex flex-row items-center">
                  {us.t1vol}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] font-[400] flex flex-row items-center">
                  {us.t2vol}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>
              </div>

              <div className="flex flex-col items-start border-solid border-t-[1px] border-[#11162b] py-[15px] h-[100%] w-[100%]">
                              <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px]">
              Fees
              </h2>
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700]">
                  ~${us.fees}
                </h2>

                <h2 className="dark:text-[#959cbf] text-[12px] py-[5px] font-[400] flex flex-row items-center">
                  {us.t1fees}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[12px] font-[400] flex flex-row items-center">
                  {us.t2fees}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>
              </div>
              <div className="flex flex-col items-start border-solid border-t-[1px] border-[#11162b] py-[15px] h-[100%] w-[100%]">
                              <h2 className="flex flex-row items-center dark:text-gray-600 text-[12px]">
              Balance
              </h2>
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700] flex flex-row items-center">
                  {us.pb1}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t1}</h2>
                </h2>
                <h2 className="dark:text-[#959cbf] text-[14px] font-[700] flex flex-row items-center">
                  {us.pb2}{" "}
                  <h2 className=" dark:text-gray-600 pl-[5px]">{us.t2}</h2>
                </h2>

                <a className="flex flex-row items-center rounded-lg border-solid border-[1px] dark:text-[#ffffff]
                 text-[black] text-[14px] px-[10px] py-[5px] mt-[10px] border-[#ffffff1a] cursor-pointer bg-transparent">
                    <img 
                        src="./images/pig.svg"
                        className="pr-[5px]"
                    />
                    Deposit
                </a>
              </div>
            </section>
          ))}
        </section>

      </section>

      <section
        className={` ${
          theme === "dark" ? "bg-[#151b33] text-white" : "bg-[#ebe8e1]"
        } pt-[50px] `}
      >
        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Liquidity;
