

const BKlong = () => {
    return(
        <div class="">
          <div class="flex flex-col gap-12 py-12">
            <div class="mx-auto bg-white dark:bg-neutral-200 p-6 rounded-full">
              <img
                src="./images/ae.svg"
                class="h-12 w-12"
                alt="Aerodrome Finance"
              />
            </div>
            <div class="mx-auto text-center space-y-4">
              <div class="text-xs sm:text-sm uppercase opacity-30 dark:text-white">
                Aerodrome BRAND KIT
              </div>
              <div class="md:w-96 text-3xl font-bold text-center dark:text-white text-[black]">
                Guide and Logos for the Aerodrome Brand
              </div>
            </div>
            <div class="xl:w-9/12 mx-auto pt-6 space-y-4">
              <div class="pb-20 text-lg text-center px-8 md:px-32 opacity-30 dark:text-white text-[black]">
                Aerodrome Finance is a next-generation AMM that combines the
                best of Curve, Convex and Uniswap, designed to serve as Base's
                central liquidity hub. Aerodrome NFTs vote on token emissions
                and receive incentives and fees generated by the protocol.
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <div class="shadow-sm rounded-lg">
                  <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div>
                      <div class="bg-neutral-200/50 dark:bg-[#11162b4d] py-12 flex justify-center rounded-t-md">
                        <div class="w-16 h-16 flex items-center justify-center bg-white dark:bg-neutral-200 p-2 rounded-full">
                          <img
                            src="./images/ae.svg"
                            class="h-9"
                            alt="Aerodrome Finance"
                          />
                        </div>
                      </div>
                      <div class="p-6 text-base dark:text-white ">Token</div>
                    </div>
                    <div class="p-6 ">
                      <div class="text-gray-600 dark:text-gray-400 text-xs">
                        <div class="flex justify-between">
                          <div class="text-gray-400 dark:text-gray-600">
                            Download
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/token.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                            <div class="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                              Raster
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/token.png"
                                target="_blank"
                                rel="noreferrer"
                              >
                                PNG
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shadow-sm rounded-lg">
                                   <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div>
                      <div class="bg-neutral-200/50 dark:bg-gray-900/30 py-8 h-[160px] flex justify-center rounded-t-md items-center">
                        <div class="w-16 h-16 flex items-center justify-center">
                          <img
                            src="./images/ae.svg"
                            class="h-12"
                            alt="Aerodrome Finance"
                          />
                        </div>
                      </div>
                      <div class="p-6 text-base dark:text-white ">Logo</div>
                    </div>
                    <div class="p-6">
                      <div class="text-gray-600 dark:text-gray-400 text-xs">
                        <div class="flex justify-between">
                          <div class="text-gray-400 dark:text-gray-600">
                            Download
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/symbol.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                            <div class="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                              Raster
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/symbol.png"
                                target="_blank"
                                rel="noreferrer"
                              >
                                PNG
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shadow-sm rounded-lg">
                                   <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div>
                      <div class="bg-neutral-200/50 dark:bg-gray-900/30 py-8 h-[160px] flex justify-center rounded-t-md items-center">
                        <div class="flex items-center h-16">
                          <img
                            src="./images/ae.svg"
                            class="mr-5 h-9"
                            alt="Aerodrome Finance"
                          />
                          <svg
                            src="/wordmark.svg"
                            class="injected-svg h-4 w-auto dark:text-white"
                            alt="Aerodrome Finance"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 100 9"
                            height="9"
                            width="100"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M8.92686 8.75425L8.18707 7.06505H3.73597L2.99617 8.75425H0L4.01955 0.123299H7.90348L11.9107 8.75425H8.92686ZM4.59906 5.11692H7.32397L5.96768 2.02211L4.59906 5.11692Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M21.4208 2.23172H15.0093V3.51403H21.4208V5.36352H15.0093V6.64583H21.4208V8.75425H12.2967V0.123299H21.4208V2.23172Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M32.0876 3.35374C32.0876 4.68537 31.4464 5.73341 30.3984 6.23894L31.9519 8.75425H28.7462L27.4638 6.59651H24.7143V8.75425H22.0017V0.123299H28.7831C30.7436 0.123299 32.0876 1.43027 32.0876 3.35374ZM24.7143 2.41667V4.30314H28.1666C28.6722 4.30314 29.375 4.30314 29.375 3.35374C29.375 2.41667 28.6722 2.41667 28.1666 2.41667H24.7143Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M37.9357 8.87754C34.1381 8.87754 32.5105 7.36096 32.5105 4.42644C32.5105 1.50425 34.1381 0 37.9357 0C41.721 0 43.3609 1.51658 43.3609 4.42644C43.3609 7.34863 41.721 8.87754 37.9357 8.87754ZM37.9357 6.58418C40.0441 6.58418 40.6483 5.75807 40.6483 4.42644C40.6483 3.10714 40.0564 2.29337 37.9357 2.29337C35.8273 2.29337 35.2231 3.09481 35.2231 4.42644C35.2231 5.7704 35.8396 6.58418 37.9357 6.58418Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M50.2902 0.123299C52.4356 0.123299 54.396 1.50425 54.396 4.43877C54.396 7.37329 52.4356 8.75425 50.2902 8.75425H43.9403V0.123299H50.2902ZM49.3654 6.46088C50.5491 6.46088 51.6835 6.28826 51.6835 4.43877C51.6835 2.58928 50.5491 2.41667 49.3654 2.41667H46.6529V6.46088H49.3654Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M65.0677 3.35374C65.0677 4.68537 64.4265 5.73341 63.3785 6.23894L64.9321 8.75425H61.7263L60.444 6.59651H57.6944V8.75425H54.9818V0.123299H61.7633C63.7237 0.123299 65.0677 1.43027 65.0677 3.35374ZM57.6944 2.41667V4.30314H61.1468C61.6523 4.30314 62.3551 4.30314 62.3551 3.35374C62.3551 2.41667 61.6523 2.41667 61.1468 2.41667H57.6944Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M70.9158 8.87754C67.1182 8.87754 65.4907 7.36096 65.4907 4.42644C65.4907 1.50425 67.1182 0 70.9158 0C74.7011 0 76.341 1.51658 76.341 4.42644C76.341 7.34863 74.7011 8.87754 70.9158 8.87754ZM70.9158 6.58418C73.0242 6.58418 73.6284 5.75807 73.6284 4.42644C73.6284 3.10714 73.0366 2.29337 70.9158 2.29337C68.8074 2.29337 68.2033 3.09481 68.2033 4.42644C68.2033 5.7704 68.8197 6.58418 70.9158 6.58418Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M90.1381 0.123299V8.75425H87.4255V3.72364L84.454 8.75425H82.6045L79.633 3.71131V8.75425H76.9204V0.123299H80.3974L83.5416 5.63477L86.6734 0.123299H90.1381Z"
                              fill="currentColor"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M100 2.23172H93.5884V3.51403H100V5.36352H93.5884V6.64583H100V8.75425H90.8759V0.123299H100V2.23172Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="p-6 text-base dark:text-white ">Wordmark</div>
                    </div>
                    <div class="p-6">
                      <div class="text-gray-600 dark:text-gray-400 text-xs">
                        <div class="flex justify-between pb-2.5">
                          <div class="text-gray-400 dark:text-gray-600">
                            Download Dark
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/logo-dark.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                            <div class="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                              Raster
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/logo-dark.png"
                                target="_blank"
                                rel="noreferrer"
                              >
                                PNG
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                          <div class="text-gray-400 dark:text-gray-600">
                            Download Light
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/logo-light.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                            <div class="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                              Raster
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="brand-kit/logo-light.png"
                                target="_blank"
                                rel="noreferrer"
                              >
                                PNG
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shadow-sm rounded-lg">
                                   <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div>
                      <div class="bg-neutral-200/50 dark:bg-gray-900/30 py-8 h-[160px] flex justify-center rounded-t-md items-center">
                        <div class="w-16 h-16 flex items-center justify-center bg-[#0052ff] p-2 rounded-full"></div>
                      </div>
                      <div class="p-6 text-base dark:text-white ">Primary Color</div>
                    </div>
                    <div class="p-6">
                      <div class="text-gray-600 dark:text-gray-400 text-xs">
                        <div class="flex justify-between pb-2.5">
                          <div class="text-gray-400 dark:text-gray-600">
                            HEX
                          </div>
                          <div class="flex">
                            <div>#2A54F3</div>
                          </div>
                        </div>
                        <div class="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                          <div class="text-gray-400 dark:text-gray-600">
                            RGB
                          </div>
                          <div class="flex gap-3">
                            <div>
                              <span class="opacity-50">R</span> 42
                            </div>
                            <div>
                              <span class="opacity-50">G</span> 84
                            </div>
                            <div>
                              <span class="opacity-50">B</span> 243
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shadow-sm rounded-lg">
                                   <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div>
                      <div class="bg-neutral-200/50 dark:bg-gray-900/30 py-8 h-[160px] flex justify-center rounded-t-md items-center">
                        <div class="text-4xl font-bold pt-3 h-16 dark:text-white text-[black]">Typeface</div>
                      </div>
                      <div class="p-6 text-base dark:text-white ">Primary Font</div>
                    </div>
                    <div class="p-6">
                      <div class="text-gray-600 dark:text-gray-400 text-xs pb-2.5"></div>
                      <div class="flex gap-1 text-gray-600 dark:text-gray-400 text-xs pb-2.5">
                        <div class="text-gray-400 dark:text-gray-600">
                          <span class="font-bold text-gray-600 dark:text-gray-400">
                            Inter Family
                          </span>{" "}
                          from
                        </div>
                        <div>
                          <a
                            class="underline hover:no-underline cursor-pointer"
                            href="https://fonts.google.com/specimen/Inter"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Google Fonts
                          </a>
                        </div>
                      </div>
                      <div class="text-xs flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                        <div class="text-gray-400 dark:text-gray-600">
                          Licensed under the{" "}
                          <a
                            class="underline hover:no-underline cursor-pointer"
                            href="https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&amp;id=OFL"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open Font License
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="shadow-sm rounded-lg">
                                   <div class="h-[400px] flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-[#1b2038]
                   dark:bg-[#1e233d] text-sm first:rounded-t-md last:rounded-b-md">
                    <div class="bg-neutral-200/50 dark:bg-gray-900/30 py-8 h-[160px] flex justify-center rounded-t-md">
                      <div className="h-[200px]">
                        <img
                          src="./images/aedark.svg"
                          class="dark:hidden h-24"
                          alt="Aerodrome Finance"
                        />
                        <img
                          src="./images/aewhite.svg"
                          class="hidden dark:inline h-24"
                          alt="Aerodrome Finance"
                        />
                      </div>
                    </div>
                    <div class="p-6">
                      <div class="text-gray-600 dark:text-gray-400 text-xs">
                        <div class="flex justify-between pb-1.5">
                          <div class="text-gray-400 dark:text-gray-600">
                            Planes
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="/brand-kit/ill-planes.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-between py-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                          <div class="text-gray-400 dark:text-gray-600">
                            Abstract Graphics
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="/brand-kit/ill-abstract.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                          <div class="text-gray-400 dark:text-gray-600">
                            Human Graphics
                          </div>
                          <div class="flex">
                            <div class="text-gray-400 dark:text-gray-600 pr-2">
                              Vector
                            </div>
                            <div>
                              <a
                                class="underline hover:no-underline cursor-pointer"
                                href="/brand-kit/ill-human.svg"
                                target="_blank"
                                rel="noreferrer"
                              >
                                SVG
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-gray-600 dark:text-gray-400 text-xs space-y-1">
                        <div class="pt-12">For more illustrations,</div>
                        <div>
                          please reach out on{" "}
                          <a
                            class="underline hover:no-underline cursor-pointer"
                            href="https://discord.gg/aerodrome"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Discord
                          </a>
                          .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default BKlong