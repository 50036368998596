const Det = [
    {
      id: 1,
      value: "$ 318.94M",
      name: "Total Value Locked"
    },
    {
      id: 2,
      value: "$ 1.08B",
      name: "Annualized Trading"
    },
    {
      id: 3,
      value: "$ 14.21M",
      name: "Annualized Fees"
    },
    {
      id: 4,
      value: "32.76K",
      name: "Active Users"
    },
  ];


  export default Det