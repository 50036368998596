import { Link } from "react-router-dom";
import { useState } from "react";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useGatekeeper } from "../../context/Gatekeeper";
import useWeb3Actions from "../hooks/useWeb3Actions"
import { devLog, truncate } from '../../util/helpers';

const Connect = ({theme}) => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { isLoading, setIsLoading } = useGatekeeper();
  const { action } = useWeb3Actions({ isLoading, setIsLoading })

  let log = (
    <span className="inline">
      <svg
        src="/wordmark.svg"
        className="injected-svg h-4 w-auto dark:text-white"
        alt="Aerodrome Finance"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 100 9"
        height="9"
        width="100"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M8.92686 8.75425L8.18707 7.06505H3.73597L2.99617 8.75425H0L4.01955 0.123299H7.90348L11.9107 8.75425H8.92686ZM4.59906 5.11692H7.32397L5.96768 2.02211L4.59906 5.11692Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M21.4208 2.23172H15.0093V3.51403H21.4208V5.36352H15.0093V6.64583H21.4208V8.75425H12.2967V0.123299H21.4208V2.23172Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M32.0876 3.35374C32.0876 4.68537 31.4464 5.73341 30.3984 6.23894L31.9519 8.75425H28.7462L27.4638 6.59651H24.7143V8.75425H22.0017V0.123299H28.7831C30.7436 0.123299 32.0876 1.43027 32.0876 3.35374ZM24.7143 2.41667V4.30314H28.1666C28.6722 4.30314 29.375 4.30314 29.375 3.35374C29.375 2.41667 28.6722 2.41667 28.1666 2.41667H24.7143Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M37.9357 8.87754C34.1381 8.87754 32.5105 7.36096 32.5105 4.42644C32.5105 1.50425 34.1381 0 37.9357 0C41.721 0 43.3609 1.51658 43.3609 4.42644C43.3609 7.34863 41.721 8.87754 37.9357 8.87754ZM37.9357 6.58418C40.0441 6.58418 40.6483 5.75807 40.6483 4.42644C40.6483 3.10714 40.0564 2.29337 37.9357 2.29337C35.8273 2.29337 35.2231 3.09481 35.2231 4.42644C35.2231 5.7704 35.8396 6.58418 37.9357 6.58418Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M50.2902 0.123299C52.4356 0.123299 54.396 1.50425 54.396 4.43877C54.396 7.37329 52.4356 8.75425 50.2902 8.75425H43.9403V0.123299H50.2902ZM49.3654 6.46088C50.5491 6.46088 51.6835 6.28826 51.6835 4.43877C51.6835 2.58928 50.5491 2.41667 49.3654 2.41667H46.6529V6.46088H49.3654Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M65.0677 3.35374C65.0677 4.68537 64.4265 5.73341 63.3785 6.23894L64.9321 8.75425H61.7263L60.444 6.59651H57.6944V8.75425H54.9818V0.123299H61.7633C63.7237 0.123299 65.0677 1.43027 65.0677 3.35374ZM57.6944 2.41667V4.30314H61.1468C61.6523 4.30314 62.3551 4.30314 62.3551 3.35374C62.3551 2.41667 61.6523 2.41667 61.1468 2.41667H57.6944Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M70.9158 8.87754C67.1182 8.87754 65.4907 7.36096 65.4907 4.42644C65.4907 1.50425 67.1182 0 70.9158 0C74.7011 0 76.341 1.51658 76.341 4.42644C76.341 7.34863 74.7011 8.87754 70.9158 8.87754ZM70.9158 6.58418C73.0242 6.58418 73.6284 5.75807 73.6284 4.42644C73.6284 3.10714 73.0366 2.29337 70.9158 2.29337C68.8074 2.29337 68.2033 3.09481 68.2033 4.42644C68.2033 5.7704 68.8197 6.58418 70.9158 6.58418Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M90.1381 0.123299V8.75425H87.4255V3.72364L84.454 8.75425H82.6045L79.633 3.71131V8.75425H76.9204V0.123299H80.3974L83.5416 5.63477L86.6734 0.123299H90.1381Z"
          fill="currentColor"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M100 2.23172H93.5884V3.51403H100V5.36352H93.5884V6.64583H100V8.75425H90.8759V0.123299H100V2.23172Z"
          fill="currentColor"
        ></path>
      </svg>
    </span>
  );

  const li = [
    {
      id: 1,
      text: "Low fee, low slippage swaps",
    },
    {
      id: 2,
      text: "Support for stable and volatile pairs",
    },
    {
      id: 3,
      text: "Self-optimizing liquidity flywheel",
    },
    {
      id: 4,
      text: "100% of fees and incentives go to voters",
    },
  ];

  return (
    <section className="flex flex-row items-start justify-between dark:text-white text-black">
      <section className="w-[50%] bg-white dark:bg-[#11162b] dark:text-white text-black min-h-[100vh] hidden lg:flex flex-col items-center justify-start ">
        <section className="flex flex-col items-start pt-[100px] dark:text-white text-black">
          <Link
            to="/"
            className="flex flex-row items-center w-[30%] justify-start"
          >
            <img src="./images/log.svg" className="w-[30px] mr-[10px]" />
            {log}
          </Link>

          <div className="flex flex-col items-start text-black dark:text-white pt-[50px]">
            <h2 className="flex text-black dark:text-white  flex-col items-start text-[3rem]">
              The central <br />
              trading & liquidity <br />
              marketplace
            </h2>
            <h2 className="flex  text-black dark:text-white flex-row items-center text-[3rem]">
              on{" "}
              <img
                src="./images/blogo.svg"
                className="w-[120px] cursor-pointer hover:opacity-70 ml-[10px]"
              />
            </h2>
          </div>


          <div className="pt-[50px]">
            {li.map((us, id) => (
              <h2
                key={id}
                className="flex flex-row items-center text-black dark:text-white pt-[10px]"
              >
                <img src="./images/check.svg" className="mr-[10px]" />
                {us.text}
              </h2>
            ))}
          </div>
        </section>
      </section>

      <section className={`w-[100%] lg:w-[50%]  ${theme === "dark" ? "dk" : "bg-[#eae8e2]"}  min-h-[100vh]
       px-[20px] flex flex-col items-center justify-center`}>
        <Link
          to="/"
          className="lg:hidden flex flex-row items-center w-[100%] justify-center pb-[50px]"
        >
          <img src="./images/log.svg" className="w-[30px] mr-[10px]" />
          {log}
        </Link>
        {!isConnected && <section className="dark:bg-gray-500 bg-[#e2e1de] dark:bg-opacity-50 w-[100%] lg:w-[60%] p-[20px] py-[40px] rounded-lg">
          <h2 className="text-[14px] dark:text-white">
            You'll need an Ethereum Wallet to sign-in. <br />
            <a className="underline text-[#0052ff] flex flex-row items-center">
              Learn more
              <img src="./images/more.svg" className="mt-[4px] ml-[5px]" />
            </a>
          </h2>
          <p className="py-4 dark:text-white text-[14px]">
            Once connected, you will have to sign a message to confirm that you
            agree to the{" "}
            <a className="text-[#0052ff] underline hover:no-underline inline cursor-pointer">
              legal disclaimer
            </a>{" "}
            before using this website.
          </p>
          <button className="bg-white flex flex-row items-center justify-center text-black hover:text-[#0052ff] mb-[10px] cursor-pointer w-[100%] rounded-lg py-[10px]" onClick={() => open()}>
            <img src="./images/bw.svg" className="mr-[10px]" /> Connect with
            Browser Wallet
          </button>
          <button className="bg-white flex flex-row items-center justify-center  text-black hover:text-[#0052ff] mb-[10px] cursor-pointer w-[100%] rounded-lg py-[10px]" onClick={() => open()}>
            <img src="./images/wc.svg" className="mr-[10px]"/> Connect with
            WalletConnect
          </button>
          <button className="bg-white flex flex-row items-center justify-center  text-black hover:text-[#0052ff] mb-[10px] cursor-pointer w-[100%] rounded-lg py-[10px]" onClick={() => open()}>
            <img src="./images/cb.svg" className="mr-[10px]"/> Connect with
            Coinbase Wallet
          </button>
          <button className="bg-white flex flex-row items-center justify-center  text-black hover:text-[#0052ff] mb-[10px] cursor-pointer w-[100%] rounded-lg py-[10px]" onClick={() => open()}>
            <img src="./images/safe.svg" className="mr-[10px]" /> Connect with
            Safe
          </button>
        </section>}

        {isConnected && <div className="w-full bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-lg text-sm py-6 sm:py-10 px-5 sm:px-12 max-w-sm">
          <div className="pb-8">
            <div className="text-xs pb-1">Connected with:</div>
            <span className="font-mono " onClick={() => open()}>{truncate(address)}</span>
          </div>
          <p className="pb-8 sm:pr-12">
            Please make sure that you have read and that you agree to the{" "}
            <a className="text-primary underline hover:no-underline inline cursor-pointer">
              legal disclaimer
            </a>{" "}
            before continuing.
          </p>
          <button
            type="button"
            onClick={() => action()}
            disabled={isLoading}
            className={`${isLoading? 'cursor-not-allowed': ''} text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full`}
          >
            <span className="flex items-center rounded-md text-base px-5 py-2.5">
            { isLoading? <span role="status">
                          <svg
                            fill="none"
                            viewBox="0 0 100 101"
                            className="inline animate-spin text-gray-200 fill-gray-600 dark:text-gray-600 dark:fill-gray-300 w-3 h-3 mr-3 mb-0.5">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"/>
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"/>
                          </svg>
                        </span> : null}
              Sign-in
            </span>
          </button>
        </div>
}

        <h2 className="text-[14px] dark:text-white pt-[50px]">
          2024 © Aerodrome Finance. v1.0.0beta+ebe35d
        </h2>
      </section>
    </section>
  );
};

export default Connect;
