import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
import React from "react";

const Mirrors = ({ theme }) => {
  const initialFocusRef = React.useRef();
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="top"
      closeOnBlur={false}
      className="z-40"
    >
      <PopoverTrigger>
        <button
          className={`rounded-lg px-[10px] font-[900] p-[5px] text-[14px] border-solid border-[1px]
        ${
          theme === "dark"
            ? "border-gray-900 hover:border-[#ffffff33]"
            : "border-[#11162b1a]"
        }
          `}
        >
          Mirrors
          <i class="bx bx-chevron-left pl-[5px]"></i>
        </button>
      </PopoverTrigger>
      <PopoverContent
        color="white"
        borderColor="blue.800"
        className="relative bg-white dark:bg-[#11162b] right-[70px] lg:right-[150px] rounded-lg"
      >
        <div class="px-4 py-3 max-w-min text-gray-500">
          <div class="border-b border-gray-100 dark:border-gray-800 w-48 pb-4 pr-10 text-xs">
            Available centralized (web2) and decentralized (web3) website
            mirrors.
          </div>
          <div class="pt-4 pb-4 text-xs">Centralized:</div>
          <div class="space-y-1">
            <div class="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
              <a
                href="https://aerodrome.finance"
                target="_blank"
                class="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                rel="noreferrer"
              >
                aerodrome.finance
              </a>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" x2="21" y1="14" y2="3"></line>
              </svg>
            </div>
            <div class="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
              <a
                href="https://alt.aerodrome.finance"
                target="_blank"
                class="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                rel="noreferrer"
              >
                alt.aerodrome.finance
              </a>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" x2="21" y1="14" y2="3"></line>
              </svg>
            </div>
          </div>
          <div class="pt-6 pb-4 text-xs">Decentralized (IPFS):</div>
          <div class="space-y-1">
            <div class="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
              <a
                href="https://aero.drome.eth"
                target="_blank"
                class="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                rel="noreferrer"
              >
                aero.drome.eth
              </a>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" x2="21" y1="14" y2="3"></line>
              </svg>
            </div>
            <div class="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
              <a
                href="https://aero.drome.eth.limo"
                target="_blank"
                class="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                rel="noreferrer"
              >
                aero.drome.eth.limo
              </a>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" x2="21" y1="14" y2="3"></line>
              </svg>
            </div>
            <div class="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
              <a
                href="https://aero.drome.eth.link"
                target="_blank"
                class="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                rel="noreferrer"
              >
                aero.drome.eth.link
              </a>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" x2="21" y1="14" y2="3"></line>
              </svg>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default Mirrors;
