import { useState } from "react";

const Inc = () => {
  const [cont, setCont] = useState(false);
  return (
    <section>
      <div class="md:bg-plane md:dark:bg-plane-light bg-contain lg:bg-auto bg-no-repeat bg-[center_bottom_0px]">
        <div class="pt-8 md:pt-16 md:pb-48">
          <div class="lg:max-w-screen-lg m-auto">
            <div>
              <div class="lg:flex gap-6">
                <div class="lg:w-8/12 mb-4 lg:mb-0 bg-white dark:bg-[#1e233d] p-6 sm:p-12 rounded-lg shadow text-gray-600 dark:text-gray-400">
                  <div class="cursor-pointer hover:animate-pulse flex flex-col sm:flex-row gap-2 justify-between sm:items-center ">
                    <div class="flex flex-col sm:flex-row sm:justify-between">
                      <div class="flex gap-2 sm:gap-3.5">
                        <div class="flex -space-x-2 w-16 w-auto px-1.5 py-1.5 shrink-0">
                          <div class="flex -space-x-2">
                            <img
                              src="./images/t2.svg"
                              class="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                            />
                            <img
                              src="https://assets.smold.app/api/token/8453/0x4200000000000000000000000000000000000006/logo.svg"
                              class="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="text-sm tracking-wide text-gray-800 dark:text-gray-200 mb-1">
                            <strong>vAMM-$BIF/WETH</strong>
                          </div>
                          <div class="text-xs text-gray-600 dark:text-gray-400 space-y-1">
                            <div class="flex gap-1.5 items-center">
                              Volatile Pool<span class="opacity-30">·</span>
                              <div
                                class="inline cursor-pointer"
                                data-testid="flowbite-tooltip-target"
                              >
                                <div class="hover:opacity-60"> 0.3%</div>
                              </div>
                              <div
                                data-testid="flowbite-tooltip"
                                tabindex="-1"
                                class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                // style="position: absolute; top: 187px; left: 316.2px;"
                                id=":r2:"
                                role="tooltip"
                              >
                                <div class="relative z-20">
                                  Pool trading fee
                                </div>
                                <div
                                  class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                  data-testid="flowbite-tooltip-arrow"
                                  //   style="bottom: -4px; left: 60.5px;"
                                >
                                  &nbsp;
                                </div>
                              </div>
                              <span class="opacity-30">·</span>
                              <div
                                class="inline cursor-pointer"
                                data-testid="flowbite-tooltip-target"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="hover:opacity-60"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M12 16v-4"></path>
                                  <path d="M12 8h.01"></path>
                                </svg>
                              </div>
                              <div
                                data-testid="flowbite-tooltip"
                                tabindex="-1"
                                class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm invisible opacity-0 border border-gray-900/10 bg-white dark:border-white/10 dark:bg-gray-700"
                                // style="position: absolute; top: 64px; left: 305.617px;"
                                id=":r4:"
                                role="tooltip"
                              >
                                <div class="relative z-20">
                                  <div class="sm:w-48 text-xs space-y-2 my-1">
                                    <div class="space-y-2 bg-neutral-100/50 hover:bg-neutral-100 dark:bg-gray-900/10 dark:hover:bg-gray-900/20 p-3 rounded-sm">
                                      <div class="text-gray-600 dark:text-gray-400">
                                        Pool Address
                                      </div>
                                      <div class="flex items-center gap-2">
                                        <span class="font-mono ">
                                          0xD9ED...D8bb4
                                        </span>
                                        <div
                                          class="inline cursor-pointer"
                                          data-testid="flowbite-tooltip-target"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="hover:opacity-50"
                                          >
                                            <rect
                                              width="14"
                                              height="14"
                                              x="8"
                                              y="8"
                                              rx="2"
                                              ry="2"
                                            ></rect>
                                            <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                                          </svg>
                                        </div>
                                        <div
                                          data-testid="flowbite-tooltip"
                                          tabindex="-1"
                                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                          //   style="position: absolute; top: -5px; left: 55.1085px;"
                                          id=":r6:"
                                          role="tooltip"
                                        >
                                          <div class="relative z-20">
                                            Copy to clipboard
                                          </div>
                                          <div
                                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                            data-testid="flowbite-tooltip-arrow"
                                            // style="bottom: -4px; left: 66.5px;"
                                          >
                                            &nbsp;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="space-y-2 bg-neutral-100/50 hover:bg-neutral-100 dark:bg-gray-950/10 dark:hover:bg-gray-950/20 p-3 rounded-sm">
                                      <div class="text-gray-600 dark:text-gray-400">
                                        Gauge Address
                                      </div>
                                      <div class="flex items-center gap-2">
                                        <span class="font-mono ">
                                          0x0000...00000
                                        </span>
                                        <div
                                          class="inline cursor-pointer"
                                          data-testid="flowbite-tooltip-target"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="hover:opacity-50"
                                          >
                                            <rect
                                              width="14"
                                              height="14"
                                              x="8"
                                              y="8"
                                              rx="2"
                                              ry="2"
                                            ></rect>
                                            <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                                          </svg>
                                        </div>
                                        <div
                                          data-testid="flowbite-tooltip"
                                          tabindex="-1"
                                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                          //   style="position: absolute; top: 67px; left: 55.1085px;"
                                          id=":r8:"
                                          role="tooltip"
                                        >
                                          <div class="relative z-20">
                                            Copy to clipboard
                                          </div>
                                          <div
                                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                            data-testid="flowbite-tooltip-arrow"
                                            // style="bottom: -4px; left: 66.5px;"
                                          >
                                            &nbsp;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="absolute z-10 h-2 w-2 rotate-45"
                                  data-testid="flowbite-tooltip-arrow"
                                  //   style="bottom: -4px; left: 104px;"
                                >
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between bg-gray-100 hover:bg-gray-200 dark:bg-[#1b2038] dark:hover:bg-gray-700 rounded-full px-3 py-2 sm:p-2 mt-3 sm:mt-0 sm:mr-1">
                      <div class="text-xs opacity-50 block sm:hidden">
                        Change Pool
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-chevron-down"
                      >
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="py-6 mt-6 border-y border-gray-100 dark:border-gray-700 dark:border-opacity-50">
                    <div class="flex flex-col gap-6 sm:flex-row justify-between">
                      <div class="flex flex-col gap-1 text-sm">
                        <div class="text-xs pb-1 text-gray-600 dark:text-gray-400 opacity-50">
                          Liquidity
                        </div>
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <span class="tracking-wider">
                            14,561.86<span class="opacity-60">&nbsp;$BIF</span>
                          </span>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          //   style="position: absolute; top: 276px; left: 277.95px;"
                          id=":ra:"
                          role="tooltip"
                        >
                          <div class="relative z-20">~$0.0</div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            // style="bottom: -4px; left: 27.5px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <span class="tracking-wider">
                            0.00019<span class="opacity-60">&nbsp;WETH</span>
                          </span>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          //   style="position: absolute; top: 300px; left: 264.75px;"
                          id=":rc:"
                          role="tooltip"
                        >
                          <div class="relative z-20">~$0.6453</div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            // style="bottom: -4px; left: 40.5px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col gap-1 text-sm sm:text-right">
                        <div class="text-xs pb-1 text-gray-600 dark:text-gray-400 opacity-50">
                          Your position
                        </div>
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <span class="tracking-wider">
                            0.0<span class="opacity-60">&nbsp;$BIF</span>
                          </span>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          //   style="position: absolute; top: 276px; left: 662.125px;"
                          id=":re:"
                          role="tooltip"
                        >
                          <div class="relative z-20">~$0.0</div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            // style="bottom: -4px; left: 27.5px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <span class="tracking-wider">
                            0.0<span class="opacity-60">&nbsp;WETH</span>
                          </span>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          //   style="position: absolute; top: 300px; left: 662.125px;"
                          id=":rg:"
                          role="tooltip"
                        >
                          <div class="relative z-20">~$0.0</div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            // style="bottom: -4px; left: 27.5px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col sm:flex-row gap-6 justify-between sm:items-center py-6">
                    <div>
                      <div class="text-xs text-gray-400 dark:text-gray-600">
                        APR
                      </div>
                      <div class="text-sm pt-2">
                        <span class="tracking-wider">0.0%</span>
                      </div>
                    </div>
                    <div>
                      <div class="text-xs text-gray-400 dark:text-gray-600">
                        Current Votes
                      </div>
                      <div class="text-sm pt-2"> 0.0 </div>
                    </div>
                    <div>
                      <div class="text-xs sm:text-right text-gray-400 dark:text-gray-600">
                        Current Incentives
                      </div>
                      <div class="flex gap-3 items-center sm:justify-end text-sm pt-2 px-0">
                        <div class="flex -space-x-2"></div>
                        <div class="sm:text-right"> ~$0.0</div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-12 pb-2 border-t border-gray-100 dark:border-gray-700 dark:border-opacity-50">
                    <div>
                      <div class="flex flex-col gap-1.5 sm:flex-row justify-between items-baseline mb-3">
                        <div class="text-sm text-gray-700 dark:text-gray-300">
                          Your Incentive
                        </div>
                        <div class="text-xs opacity-80 text-right flex gap-3 items-center pb-1"></div>
                      </div>
                      <div class="flex">
                        <span class="inline-flex items-center px-3 hover:bg-neutral-50 dark:hover:bg-gray-850 rounded-l-md text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 border border-r-0 border-gray-300 dark:border-gray-600">
                          <div class="w-28 flex items-center justify-between cursor-pointer">
                            <div class="flex gap-3 items-center">
                              <img
                                src="https://assets.smold.app/api/token/8453/0x940181a94A35A4569E4529A3CDfB74e38FD98631/logo.svg"
                                class="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                              />
                              <div class="text-sm truncate">AERO</div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="lucide lucide-chevron-down"
                            >
                              <path d="m6 9 6 6 6-6"></path>
                            </svg>
                          </div>
                        </span>
                        <div class="relative w-full">
                          <input
                            class="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 outline-0 rounded-r-lg p-2.5 text-sm"
                            required=""
                            value="0.0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!cont ? (
                  <div class="lg:w-6/12 p-6 sm:p-10 bg-[#0052ff] text-white rounded-lg shadow">
                    <div class="flex flex-col justify-between h-full">
                      <div>
                        <img src="./images/walk.svg" class="w-20 pb-6" />
                        <div class="font-semibold pb-4 pr-8 text-sm">
                          Incentives are usually provided by the protocols. By
                          continuing with the next steps you acknowledge that
                          you understand the mechanics of the protocol and that
                          after depositing any rewards as incentives you won't
                          be able to withdraw them.
                        </div>
                        <div class="text-sm pb-6 pr-2">
                          By providing an incentive, you may draw more liquidity
                          providers. Votes are a decisive factor on how much
                          emissions a liquidity pool will get next epoch. The
                          more emissions are flowing to a liquidity pool, the
                          more rewards for those who provide liquidity for the
                          pool.
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setCont(true);
                        }}
                        type="button"
                        class="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full"
                      >
                        <span class="flex items-center rounded-md text-sm px-4 py-2">
                          Continue
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div class="lg:w-6/12 p-6 sm:p-12 dark:bg-[#1e233d] dark:text-white bg-gray-50 bg-opacity-70 dark:bg-opacity-50 rounded-lg shadow">
                    <div class="text-xl mb-4">Incentivize</div>
                    <div class="text-sm mb-8 leading-6 text-gray-600 dark:text-gray-400">
                      Voting and adding incentives for this epoch ends in
                      <strong>
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          {" "}
                          5 days
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          // style="position: absolute; top: 921.25px; left: 8px;"
                          id=":ri:"
                          role="tooltip"
                        >
                          <div class="relative z-20">
                            Thu Mar 28 2024 01:00:00 GMT+0100 (West Africa
                            Standard Time)
                          </div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            //   style="bottom: -4px; left: 63.4252px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </strong>{" "}
                      and there will be{" "}
                      <span class="font-semibold">
                        <div
                          class="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <span class="tracking-wider">
                            12,879,062.76
                            <span class="opacity-60">&nbsp;AERO</span>
                          </span>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabindex="-1"
                          class="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          // style="position: absolute; top: 965.25px; left: 54.5418px;"
                          id=":rk:"
                          role="tooltip"
                        >
                          <div class="relative z-20">~$9,710,710.06</div>
                          <div
                            class="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            //   style="bottom: -4px; left: 57px;"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </span>{" "}
                      distributed to all liquidity providers. By providing an
                      incentive, you draw more liquidity providers to this pool.
                    </div>
                    <div class="flex-col">
                      <div class="py-2 pl-2">
                        <ol
                          data-testid="timeline-component"
                          class="relative border-l border-gray-200 dark:border-gray-700"
                        >
                          <li data-testid="timeline-item" class="mb-10 ml-6">
                            <div data-testid="timeline-point" class="">
                              <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                <div class="text-xs font-bold">1</div>
                              </span>
                            </div>
                            <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                              Select the liquidity pool to incentivize.
                            </time>
                          </li>
                          <li data-testid="timeline-item" class="mb-10 ml-6">
                            <div data-testid="timeline-point" class="">
                              <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                <div class="text-xs font-bold">2</div>
                              </span>
                            </div>
                            <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                              Select the token you want to use for the
                              incentive.
                            </time>
                          </li>
                          <li
                            data-testid="timeline-item"
                            class="mb-10 ml-6 !mb-0"
                          >
                            <div data-testid="timeline-point" class="">
                              <span class="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                <div class="text-xs font-bold">3</div>
                              </span>
                            </div>
                            <time class="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                              Specify the amount of your incentive.
                            </time>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inc;
