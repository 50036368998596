import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { TL } from "../Maps";
import { Link } from "react-router-dom";

const Tokenlist = ({ theme, setTheme }) => {
  const [toggle, setToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <section
      className={`${theme === "dark" ? "bg-[#151b33]" : "bg-[#ebe8e1]"}
       min-h-[100vh]`}
    >
      <Sidebar
        toggle={toggle}
        setToggle={setToggle}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        theme={theme}
      />

      <section
        className={` ${
          theme === "light" && "bg-[#ebe8e1]"
        } px-[30px] lg:px-[50px]`}
      >
        <Header theme={theme} />
      </section>

      <section
        className={`flex flex-col items-center justify-start pt-[50px] px-[20px] lg:px-[100px]
 min-h-[70vh]`}
      >
        <section className="flex flex-row items-center justify-between w-[100%]">
          <div
            className={`w-[100%] h-[100%] ${
              theme === "dark" && "hrr"
            } p-[20px] rounded-lg border-solid border-[1px] 
       border-white dark:border-opacity-30 flex flex-col lg:flex-row items-start lg:items-center justify-between`}
          >
            <img
              src="https://aerodrome.finance/svg/plane-light.svg"
              className="absolute w-[300px] lg:w-[200px] dark:flex hidden"
            />
            <img
              src="https://aerodrome.finance/svg/plane.svg"
              className="absolute w-[300px] lg:w-[200px] dark:hidden flex"
            />
            <h2 className="dark:text-white text-[black] w-[70%] leading-[1.5rem] text-[14px] ">
            <strong className="font-[900]">The tokens listed here represent our <i>"tokenlist"</i> .</strong> The liquidity pools for these tokens receive emissions and incentives, are fetched directly from our smart contracts, and the price for every token is fetched from our on-chain oracle in real-time.
            </h2>

            <Link to="/join" className="capitalize z-50 dark:text-white text-[black] border-solid border-[1px] mt-[30px] lg:mt-[0px] border-[#ffffff1a] py-[10px] rounded-lg px-[15px]">
              list your token
            </Link>


          </div>

        </section>

        <section className="flex flex-col items-start w-[100%] pt-[50px]">


          <section className="flex flex-col lg:flex-row items-center justify-between w-[100%] py-[20px]">
            <div className="w-[100%] lg:w-[70%] overflow-x-scroll flex-nowrap flex flex-row items-center">
            <h2 className="dark:text-white text-[black] text-[1.25rem]">
            84 Listed Tokens
          </h2>
            </div>

            <div
              className="w-[100%] mt-[10px] lg:mt-[0px] lg:w-[25%] bg-transparent border-solid border-[1px] text-[#6b7280] border-[#6b7280] rounded-lg px-2.5 py-[5px]
            flex flex-row items-center"
            >
              <i class="bx bx-search text-[20px]"></i>
              <input
                type="text"
                placeholder="Symbol or Address"
                className="bg-transparent w-[90%] text-[black] dark:text-white pl-[20px]"
              />
            </div>
          </section>

        </section>

        <section className="hidden lg:flex flex-col items-start w-[100%] py-[10px]">
          {TL.map((us, id) => (
            <section
              key={id}
              className={`flex flex-row items-start w-[100%] border-solid bg-white mt-[1px] dark:border-[1px] dark:border-[#11162b] rounded-lg p-[20px]
                dark:bg-[#1e233d] justify-between `}
            >
              <div className="flex flex-row items-start h-[100%]">
                <div className="flex flex-row items-center pr-[20px]">
                  <img src={us.image1} className="" />
                  
                </div>
                <div>
                  <h2 className="text-black text-[14px] font-[700] dark:text-white">
                    {us.t1}
                  </h2>
                  <h2 className="dark:text-[#959cbf] text-[12px]">
                    6e4wfvbw354tghwn4bter
                  </h2>

                </div>
              </div>

              <div className="flex flex-col items-end justify-between h-[100%]">
                <h2 className="dark:text-white text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#444b6c] text-[12px]">
                oracle quote per token
                </h2>
              </div>

              <div className="flex flex-col items-end justify-between h-[100%]">
                <h2 className="dark:text-white text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#444b6c] text-[12px]">
                  in your wallet
                </h2>
              </div>

            </section>
          ))}
        </section>

        <section className="lg:hidden flex flex-col items-start w-[100%] py-[10px]">
          {TL.map((us, id) => (
            <section
              key={id}
              className="flex flex-col items-start w-[100%] border-solid dark:border-[1px] dark:border-[#11162b] rounded-lg p-[20px]
                dark:bg-[#1e233d] bg-white justify-between hover:opacity-70"
            >
              <div className="flex flex-row items-start w-[100%] h-[100%]">
              <div className="flex flex-row items-start h-[100%]">
                <div className="flex flex-row items-center pr-[20px]">
                  <img src={us.image1} className="" />
                  
                </div>

              </div>

              <div>
                  <h2 className="text-black text-[14px] font-[700] dark:text-white">
                    {us.t1}
                  </h2>
                  <h2 className="dark:text-[#959cbf] text-[12px]">
                    6e4wfvbw354tghwn4bter
                  </h2>

                  <div className="flex flex-col items-start py-[20px] justify-between h-[100%]">
                <h2 className="dark:text-white text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#444b6c] text-[12px]">
                oracle quote per token
                </h2>
              </div>

              <div className="flex flex-col items-start justify-between h-[100%]">
                <h2 className="dark:text-white text-[14px] font-[700]">
                  ~${us.vol}
                </h2>
                <h2 className="dark:text-[#444b6c] text-[12px]">
                  in your wallet
                </h2>
              </div>
                </div>


              </div>

            </section>
          ))}
        </section>

      </section>

      <section
        className={` ${
          theme === "dark" ? "bg-[#151b33] text-white" : "bg-[#ebe8e1]"
        } pt-[50px] `}
      >
        <Footer
          theme={theme}
          setTheme={setTheme}
          onOpen={onOpen}
          onClose={onClose}
        />
      </section>
    </section>
  );
};

export default Tokenlist;
