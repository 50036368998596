const Org = [
    {
      id: 1,
      name: "lido finance",
      image: "./images/b1.svg"
    },
    {
      id: 2,
      name: "sonne",
      image: "./images/b2.svg"
    },
    {
      id: 3,
      name: "overnight",
      image: "./images/b3.svg"
    },
    {
      id: 4,
      name: "inverse finance",
      image: "./images/b4.svg"
    },
    {
      id: 5,
      name: "moonwell",
      image: "./images/b5.svg"
    },
    {
      id: 6,
      name: "thales",
      image: "./images/b6.svg"
    },
    {
      id: 7,
      name: "mai finance",
      image: "./images/b7.svg"
    },
    {
      id: 8,
      name: "reverse protocol",
      image: "./images/b8.svg"
    },
    {
      id: 9,
      name: "tarot",
      image: "./images/b9.svg"
    },
  ];

  export default Org