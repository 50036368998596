import {
    Drawer,
    DrawerContent,
    DrawerCloseButton,
  } from '@chakra-ui/react'
import React from 'react'
import { Legal } from '../Maps'

const Sidebar = ({toggle, setToggle, isOpen, onOpen, onClose, theme}) => {
    
    const btnRef = React.useRef()


    return(
        <section>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >        
        <DrawerContent>
        <div className='w-[100%] h-[100%] opacity-40 bg-[black]' onClick={onClose}></div>
        <section className='bg-white dark:bg-[#1e233d] flex flex-col w-[50%] overflow-scroll absolute right-0 h-[100%]'>

        <section className=' flex flex-row p-[20px] w-[100%] justify-end'>

        <DrawerCloseButton className=' rounded-[50%] p-[10px] text-white bg-[#11162b] 
          dark:bg-gray-200 dark:bg-opacity-10 text-[10px] hover:bg-opacity-90 fixed' />
        </section>
        <section className='flex flex-col items-start w-[100%] p-[40px]'>

          
          <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[20px] font-[500]'>Legal Disclaimer</h2>

          <h2 className='text-sm opacity-80 italic dark:text-white text-[black] pb-[30px] font-[500]'>Last updated on August 10, 2023</h2>

            <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
            Please read this disclaimer carefully before using https://aerodrome.finance (hereinafter referred to as the "Website", and the services offered therein as "Our Services").
            </h2>

            <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
            The terms of use of the Website contained herein (the "Terms of Use") are entered into by and between the Website user ("You") and Aerodrome Foundation (hereinafter referred to as the "Foundation" or "We/Our/Us").
            </h2>
            <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
            By using the Website and clicking past this legal disclaimer page, you confirm that you accept this legal disclaimer, the Terms of Use and agree to comply. If you do not agree, you must not use the Website.
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                You further represent and warrant that you:
                </h2>

                <ul class="list-disc pl-[20px]">
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (a) are of legal age to form a binding contract;
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (b) have not been previously suspended or removed from using Our Services; and
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (c) have full power and authority to enter into this agreement with Foundation, and in doing so will not violate any other agreement to which you are a party.
                    </li>
                </ul>

                <h2 className='text-[16px] py-4 dark:text-white text-[black] font-[500]'>
                If you are registering to use Our Services on behalf of a legal entity, you further represent and warrant that:
                </h2>

                <ul class="list-disc pl-[20px] pb-[20px]">
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (a) such legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization, and
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (b) you are duly authorized by such legal entity to act on its behalf.
                    </li>

                </ul>

                <div className='flex flex-col items-start w-[100%]'>
                    <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px] font-[500]'>Information published is not advice</h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                    The information provided on the Website is for informational purposes and does not constitute investment advice, financial legal, regulatory, accounting, tax or similar advice, and You should not treat any of the Website's content as such.
                    </h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                    Further, nothing on this Website should be construed as a recommendation, by Us, Our affiliates, or any third party, to acquire or dispose of any investment, or to engage in any investment strategy or transaction. You should consult Your own investment, legal, tax and/or similar professionals regarding Your specific situation and any specific decisions.
                    </h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                    Our team provides the Website as a service to the public, and is not responsible for, and expressly disclaims all liability for, damages of any kind arising out of use, reference to, or reliance on any information contained within the Website. While the information contained within the Website is periodically updated, no guarantee is given that the information provided in the Website is correct, complete, and up-to-date.
                    </h2>
                    </div>

                    <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px] font-[500]'>
                    Usage restrictions
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                By accessing or using the Website or Our Services, you hereby confirm and agree that:
                </h2>
                <ul class="list-disc pl-[20px] pb-[20px]">
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (a) you are not currently residing in Afghanistan, Belarus, Bosnia and Herzegovina, Burundi, Central African Republic, China, Democratic Republic of Congo, Cuba, Ethiopia, Guinea, Guinea-Bissau, Haiti, Iran, Iraq, North Korea, Lebanon, Libya, Mali, Moldova, Montenegro, Myanmar, Nicaragua, Niger, Russia, Serbia, Somalia, South Sudan, Sudan, Syria, Tunisia, Turkey, Ukraine, Venezuela, Yemen, Zimbabwe, or any other specifically designated OFAC sanctions listed jurisdiction; and
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    (b) your access to and use of the Website and Our Services is lawful in your country of residence in the way you access and use them.
                    </li>

                </ul>

                {
                  Legal.map((us, id) => (
                    <div key={id} className='flex flex-col items-start w-[100%]'>
                    <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px]'>{us.title}</h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                      {us?.body1}
                    </h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                      {us?.body2}
                    </h2>

                    </div>
                  ))
                }

                <div className='flex flex-col items-start w-[100%]'>
                    <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px]'>Arbitration</h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                    Except for disputes related to copyrights, logos, trademarks, trade names, trade secrets or patents, any dispute, controversy or claim arising out of, relating to, or in connection with Your use of the Website or Our Services, or in connection with these Terms of Use, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, shall be finally resolved by binding arbitration by the Judicial Arbitration and Mediation Services (JAMS) pursuant to its Comprehensive Arbitration Rules and Procedures. The tribunal shall have the power to rule on any challenge to its own jurisdiction or to the validity or enforceability of any portion of the agreement to arbitrate.
                    </h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[800]'>
                    The parties agree to arbitrate solely on an individual basis, and that these Terms of Use do not permit class arbitration or any claims brought as a plaintiff or class member in any class or representative arbitration proceeding.
                    </h2>
                    <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                    The arbitral tribunal may not consolidate more than one person’s claims and may not otherwise preside over any form of a representative or class proceeding. In the event the prohibition on class arbitration is deemed invalid or unenforceable, then the remaining portions of this agreement to arbitrate will remain in force.
                    </h2>
                    </div>

                    <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px] font-[500]'>
                    Changes
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                We may revise and update the Terms of Use in this Legal Disclaimer from time to time in Our sole discretion and without notice. All changes are effective immediately when We post them and apply to all access to and use of the Website thereafter. Your continued use of the Website following the posting of revised Terms of Use means that You accept and agree to the changes. You are expected to check this page from time to time so You are aware of any changes, as they are binding on You.
                </h2>

                <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px] font-[500]'>
                Third-Party Services and Content
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                In using this Website, You may view content or utilize services provided by third parties ("Third Parties"), including links to Web pages and services of such parties ("Third-Party Content"). We do not control, endorse, or adopt any Third-Party Content and will have no responsibility for Third-Party Content, including, without limitation, material that may be misleading, incomplete, erroneous, offensive, indecent, or otherwise objectionable in Your jurisdiction. Your business dealings and/or correspondence with such Third Parties is a matter solely between You and the Third Parties. We are not liable or responsible for any loss or damage of any sort incurred as a result of such dealings and You understand that Your use of Third-Party Content, and Your interactions with Third Parties, is at your own risk.
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                We may employ third party companies to facilitate the Wallet integration with Our Services:
                </h2>

                <ul class="list-disc pl-[20px] pb-[20px]">
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    For WalletConnect, please consult <a className='underline' href='https://walletconnect.com/privacy'>
                    their privacy policy
                    </a> 
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    For Coinbase Wallet, please consult <a className='underline' href='https://www.coinbase.com/legal/'>
                    their legal agreements
                    </a> 
                    </li>
                    <li className='text-[16px] pb-2 dark:text-white text-[black] font-[500]'>
                    For (Gnosis) Safe, please consult <a className='underline' href='https://safe.global/privacy'>
                    their privacy policy
                    </a> 
                    </li>

                </ul>

                <h2 className='dark:text-[#959cbf] text-[#444b6c] text-2xl pb-[10px] font-[500]'>
                Intellectual Property Rights
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Foundation and its licensors and affiliates, and are protected by United States and international copyright, trademark, trade dress, patent, trade secret, and other intellectual property or proprietary rights laws.
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                The Foundation name and all related names, logos, product and service names, designs, and slogans are trademarks of the Foundation or its licensors or affiliates. Moreover, all intellectual property rights associated with Third Parties and Third-Party Content referenced on or offered through the Website are owned by the respective entities offering such services.
                </h2>
                <h2 className='text-[16px] pb-4 dark:text-white text-[black] font-[500]'>
                You agree not to use any Foundation or Third Party intellectual property without prior written permission from Foundation or such Third Party. You are herein granted a limited, revocable, and nonexclusive right to post excerpts from or create a hyperlink to the Website, provided that full and clear credit is given to Us with appropriate and specific direction to the original content, and provided such hyperlink is not related to any portrayal of the Foundation or the Website in any false, misleading, derogatory, or otherwise offensive manner.
                </h2>


        </section>
        </section>
        </DrawerContent>
      </Drawer>
        </section>
    )
}

export default Sidebar