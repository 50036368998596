import { Outlet } from "react-router-dom"

const Layout = ({theme, setTheme}) => {
    return(
        <section>
            <Outlet />
        </section>
    )
}

export default Layout