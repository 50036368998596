


const Liqrouting = () => {
    return(
        <div class=" pt-[20px]">
        <div class="text-xs text-gray-400 dark:text-gray-600">
          Liquidity Routing
        </div>
        <div class="mt-4 pt-6 border-t border-gray-100 dark:border-gray-700 dark:border-opacity-50">
          <div class="flex items-start justify-between h-28 sm:h-24">
            <div class="grow flex items-center justify-between">
              <img
                src="https://assets.smold.app/api/token/8453/0x940181a94A35A4569E4529A3CDfB74e38FD98631/logo.svg"
                class="w-7 h-7 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
              />
              <div class="relative grow flex justify-center items-center text-gray-600 dark:text-gray-400">
                <div class="relative z-20 rounded-full bg-gray-300 dark:bg-gray-900 p-px sm:p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevrons-right"
                  >
                    <path d="m6 17 5-5-5-5"></path>
                    <path d="m13 17 5-5-5-5"></path>
                  </svg>
                </div>
                <div class="absolute z-10 top-1.5 sm:top-2 border-t border-dashed border-gray-300 dark:border-gray-900 w-10/12"></div>
                <div class="absolute z-10 top-6 border-r-2 border-gray-200 dark:border-gray-900/50 h-3"></div>
                <div class="absolute top-10">
                  <div class="w-20 sm:w-28 text-[10px] md:text-xs  bg-[#eff0f3] dark:bg-[#1b2038] px-3 py-2.5 rounded-md">
                    <div class="animate-pulse delay-75 text-center">
                      {" "}
                      2.0%<div>Volatile</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grow flex items-center justify-between">
              <img
                src="https://assets.smold.app/api/token/8453/0x9E53e88dCff56d3062510A745952DEC4cEFDff9E/logo.svg"
                class="w-7 h-7 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
              />
              <div class="relative grow flex justify-center items-center text-gray-600 dark:text-gray-400">
                <div class="relative z-20 rounded-full bg-gray-300 dark:bg-gray-900 p-px sm:p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevrons-right"
                  >
                    <path d="m6 17 5-5-5-5"></path>
                    <path d="m13 17 5-5-5-5"></path>
                  </svg>
                </div>
                <div class="absolute z-10 top-1.5 sm:top-2 border-t border-dashed border-gray-300 dark:border-gray-900 w-10/12"></div>
                <div class="absolute z-10 top-6 border-r-2 border-gray-200 dark:border-gray-900/50 h-3"></div>
                <div class="absolute top-10">
                  <div class="w-20 sm:w-28 text-[10px] md:text-xs  bg-[#eff0f3] dark:bg-[#1b2038] px-3 py-2.5 rounded-md">
                    <div class="animate-pulse delay-75 text-center">
                      {" "}
                      0.3%<div>Volatile</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grow flex items-center justify-between">
              <img
                src="https://assets.smold.app/api/token/8453/0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA/logo.svg"
                class="w-7 h-7 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
              />
              <div class="relative grow flex justify-center items-center text-gray-600 dark:text-gray-400">
                <div class="relative z-20 rounded-full bg-gray-300 dark:bg-gray-900 p-px sm:p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevrons-right"
                  >
                    <path d="m6 17 5-5-5-5"></path>
                    <path d="m13 17 5-5-5-5"></path>
                  </svg>
                </div>
                <div class="absolute z-10 top-1.5 sm:top-2 border-t border-dashed border-gray-300 dark:border-gray-900 w-10/12"></div>
                <div class="absolute z-10 top-6 border-r-2 border-gray-200 dark:border-gray-900/50 h-3"></div>
                <div class="absolute top-10">
                  <div class="w-20 sm:w-28 text-[10px] md:text-xs  bg-[#eff0f3] dark:bg-[#1b2038] px-3 py-2.5 rounded-md">
                    <div class="animate-pulse delay-75 text-center">
                      {" "}
                      0.05%<div>Stable</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://assets.smold.app/api/token/8453/0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913/logo.svg"
              class="w-7 h-7 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
            />
          </div>
        </div>
      </div>
    )
}

export default Liqrouting