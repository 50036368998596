const Link2 = [
    {
      id: 1,
      name: "Documentation",
      link: "/documentation"
    },
    {
      id: 2,
      name: "Security",
      link: "/security"
    },
    {
      id: 3,
      name: "Brand Kit",
      link: "/brandkit"
    },
    {
      id: 4,
      name: "Tokenlist",
      link: "/tokenlist"
    }
  ]

  export default Link2