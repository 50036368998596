const Note = [
    {
      id: 1,
      text: "Stable & Volatile Trading for Low Fees"
    },
    {
      id: 2,
      text: "100% of Protocol Incentives and Fees Go to Voters"
    },
    {
      id: 3,
      text: "Liquid Locked Positions in the Form of NFTs"
    },
    {
      id: 4,
      text: "Permissionless Pools, Gauges, and Incentives"
    },
    {
      id: 5,
      text: "Self-Optimizing Liquidity Flywheel"
    },
    {
      id: 6,
      text: "Anti-dilution rebases for voters"
    },
  ];

  export default Note