const Inf = [
    {
      id: 1,
      header: "Traders",
      body: "Swap tokens with minimal slippage and pay some of the lowest fees to AERO lockers.",
      image: "./images/i1.svg"
    },
    {
      id: 2,
      header: "Liquidity Providers",
      body: "Deposit the tokens used for trading on Aerodrome and receive AERO emissions as rewards.",
      image: "./images/i2.svg"
    },
    {
      id: 3,
      header: "Protocols",
      body: "Offer incentives to veAERO voters to attract votes / AERO emissions to their pools, allowing them to build liquidity at a low cost.",
      image: "./images/i3.svg"
    },
    {
      id: 4,
      header: "veAERO Voters",
      body: "Vote on which pools will earn AERO emissions and receive 100% of incentives and fees for the pools they vote for. Any AERO holder can lock their tokens to convert to veAERO.",
      image: "./images/i4.svg"
    },
  ];

  export default Inf